<template>

    <div class="left">
      <div class="left_box">
        <div class="left_a" @click="goTopb" @mouseover="ishy=true" @mouseleave="ishy=false">
          <div class="yh" v-if="isyh"></div>
          <div class="hy" v-else></div>
          <div v-if="ishy">
            <div class="erwmb" :class="{erwmc:$t('navbar.ZH')=='中'}">{{$t('index.ISYH')}}</div>
            <!-- <div class="erwmc" v-if="isyh" >切换到英文</div>
            <div class="erwmb" v-else>Switch to Chinese</div> -->
          </div>
         
        </div>
        <div class="left_b" @mouseover="isleft=true" @mouseleave="isleft=false" @click="isconcat=true">
          <div class="kefu"></div>
          <div class="erwm" v-if="isleft">
            {{$t('index.CONUS')}}
          </div>
        </div>
        <div class="left_c" @click="goTop" v-if="isShow">
          <div class="top"></div>
        </div>
      </div>
      <!-- 联系客服弹窗 -->
    <div class="inviteDialog" v-if="isconcat">
        <div class="inviteDialog_box">
            <div class="invite_title">{{$t('index.CONUS')}}</div>
            <div class="invite_con">
              <div class="invite_le">
                <div class="invite_imga" v-if="isWeX">
                  <!-- <img :src="wxqrcode" alt=""> -->
                  <div class="qrcode_shadow">
                    <img :src="wxqrcode" alt="">
                    <!-- <qrcode-vue  :value="wxqrcode" :size="175" level="H" />  -->
                  </div>
                  
                </div>
                <div class="invite_imgb" v-else>
                  <div class="qrcode_shadow">
                    <img :src="whatqrcode" alt="">
                    <!-- <qrcode-vue  :value="whatqrcode" :size="175" level="H" />  -->
                  </div>                
                </div>
              </div>
              <div class="invite_rg">
                <div class="concat concatb" @click="isWeX=true">
                  <img v-if="isWeX" src="../../views/home/image/WeChatb.png" alt="">
                  <img v-else src="../../views/home/image/WeChata.png" alt="">
                </div>
                <div class="concat" @click="isWeX=false">
                  <img v-if="isWeX" src="../../views/home/image/WhatsAppa.png" alt="">
                  <img v-else src="../../views/home/image/WhatsAppb.png" alt="">
                </div>
              </div>
            </div>
            <div class="invite_bom"><img src="../../views/home/image/scan.png" alt="">{{$t('index.SCAN')}}</div>
        <div class="inviteclose" @click="isconcat=false"><img src="../../views/home/image/inviteclose.png" alt=""></div>
        </div>
        
    </div>
    </div>
</template>

<script>
import { getQrcode } from "@/assets/api/index";
// import QrcodeVue from 'qrcode.vue'
export default {
  data() {
    return {
        isleft:false,
        isyh:false,
        ishy:false,
        isconcat:false,
        isWeX:true,
        wxqrcode:'',
        whatqrcode:'',
        isShow:false
    };
  },
  // components:{QrcodeVue},
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
    console.log(this.$i18n.locale);
    if(this.$i18n.locale == 'zh-cn'){
      this.isyh=false
    }else{
      this.isyh=true
    }
    this.getQrcode()
  },
  methods: {
    async getQrcode() {
      const res = await getQrcode({});
      if (res.code==200) {
          this.wxqrcode = res.data.sys_wx_qrcode
          this.whatqrcode = res.data.sys_whatsapp_qrcode
      }
    },
    handleScroll() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        this.isShow = scrollTop > 200 ? true : false 
    },
    goTop(){
        document.querySelector('#onTop').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },
    goTopb(){
        if(this.$i18n.locale === 'zh-cn'){   // 判断当前语言
        this.$i18n.locale = 'en-us'   // 设置当前语言
        localStorage.setItem('language',this.$i18n.locale)
        this.isyh=true
      } else {
        this.$i18n.locale = 'zh-cn'
        localStorage.setItem('language',this.$i18n.locale)
        this.isyh=false
      }
    },
  },
};
</script>
<style lang='less' scoped>
.inviteDialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9;
}
.inviteDialog_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 500px;
    height: 482px;
    background: url('../../views/home/image/inviteb.png') no-repeat;
    padding-top: 52px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.invite_title{
    width: 100%;
    font-size: 36px;
    font-family: alibaba-font;
    font-weight: 900;
    color: #333333;
    line-height: 36px;
    text-align: center;
    margin-bottom: 28px;
}
.inviteclose{
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.invite_con{
  display: flex;
  align-items: center;
}

.invite_imga{
  position: relative;
  width: 240px;
  height: 240px;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin-right: 32px;
  box-shadow: 6px 6px 24px 0px rgba(89,122,204,0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
}
.invite_imga::after{
    border: 22px solid transparent;
    border-left: 22px solid #FFFFFF;
    content: "";
    display: block;
    position: absolute;
    right: -42px;
    top: 30px;
    z-index: 2;
}
.qrcode_shadow{
  width: 100%;
  height: 100%;
  background: url('../../views/home/image/shadow.png') no-repeat 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}
.qrcode_shadow img{
  width: 175px;
  height: 175px;
}
.invite_imgb{
  position: relative;
  width: 240px;
  height: 240px;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin-right: 32px;
  box-shadow: 6px 6px 24px 0px rgba(89,122,204,0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
}
.invite_imgb::after{
    border: 22px solid transparent;
    border-left: 22px solid #FFFFFF;
    content: "";
    display: block;
    position: absolute;
    right: -42px;
    bottom: 30px;
    z-index: 2;
}

.concat{
  width: 108px;
  height: 108px;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.concatb{
  margin-bottom: 24px;
}

.invite_bom{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 66px;
  border-top: 1px solid #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invite_bom img{
  margin-right: 4px;
}


// 吸顶
.left{
  position: fixed;
  top: 70%;
  right: 10px;
  z-index: 9;
  display: flex;
  align-items: center;
}


.left_a{
  position: relative;
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 32px 32px 32px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.left_b{
  position: relative;
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 32px 32px 32px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.left_c{
  position: relative;
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 32px 32px 32px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.erwm{
  position: absolute;
  top: 10px;
  left: -140px;
  padding: 0 30px;
  box-sizing: border-box;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background: #FFFFFF;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 8px 8px 8px 8px;
  font-size: 16px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #333333;
}
.erwm::after{
    border: 9px solid transparent;
    border-left: 9px solid #FFFFFF;
    content: "";
    display: block;
    position: absolute;
    right: -18px;
    top: 16px;
    z-index: 2;
}
.erwmb{
  position: absolute;
  top: 10px;
  left: -210px;
  padding: 0 30px;
  box-sizing: border-box;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background: #FFFFFF;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 8px 8px 8px 8px;
  font-size: 16px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #333333;
}
.erwmb::after{
    border: 9px solid transparent;
    border-left: 9px solid #FFFFFF;
    content: "";
    display: block;
    position: absolute;
    right: -18px;
    top: 16px;
    z-index: 2;
}
.erwmc{
  position: absolute;
  top: 10px;
  left: -155px;
  padding: 0 30px;
  box-sizing: border-box;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background: #FFFFFF;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 8px 8px 8px 8px;
  font-size: 16px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #333333;
}
.erwmc::after{
    border: 9px solid transparent;
    border-left: 9px solid #FFFFFF;
    content: "";
    display: block;
    position: absolute;
    right: -18px;
    top: 16px;
    z-index: 2;
}

.kefu{
  width: 30px;
  height: 30px;
  background: url('../../views/home/image/kefu.png') no-repeat;
}
.left_b:hover .kefu{
  background: url('../../views/home/image/kefub.png') no-repeat;
}
.top{
  width: 30px;
  height: 30px;
  background: url('../../views/home/image/top.png') no-repeat;
}
.left_c:hover .top{
  background: url('../../views/home/image/topb.png') no-repeat;
}
.yh{
  width: 30px;
  height: 30px;
  background: url('../../views/home/image/chin.png') no-repeat;
}
.left_a:hover .yh{
  background: url('../../views/home/image/chinb.png') no-repeat;
}
.hy{
  width: 30px;
  height: 30px;
  background: url('../../views/home/image/eng.png') no-repeat;
}
.left_a:hover .hy{
  background: url('../../views/home/image/engb.png') no-repeat;
}
</style>

import PageLeft from './Page/Left.vue'
import PageTop from './Page/Top.vue'
import PageBot from './Page/Bot.vue'
import PageCode from './Page/Code.vue'
import PageQrcode from './Page/Qrcode.vue'
import PageLogin from './Page/Login.vue'
import PageInvite from './Page/Invite.vue'
import PageOut from './Page/Out.vue'
import PageLoad from './Page/Load.vue'
import Slider from './Slider.vue'
import MobileTop from './Mobile/Top.vue'
import MobileHoll from './Mobile/Holl.vue'
import MobileLeft from './Mobile/Left.vue'
import MobileBot from './Mobile/Bot.vue'
import MobileQrcode from './Mobile/Qrcode.vue'
import MobileQrcodeb from './Mobile/Qrcodeb.vue'
import MobileInvite from './Mobile/Invite.vue'
import MobileCode from './Mobile/Code.vue'
import MobileSuc from './Mobile/Suc.vue'
import MobileOut from './Mobile/Out.vue'
import MobileLoad from './Mobile/Load.vue'
import MobileService from './Mobile/Service.vue'
import MobileSlider from './Mobile/Slider.vue'
export default {
    install(Vue) {
        Vue.component('PageTop', PageTop)
        Vue.component('PageLeft', PageLeft)
        Vue.component('PageBot', PageBot)
        Vue.component('PageCode', PageCode)
        Vue.component('PageLogin', PageLogin)
        Vue.component('PageQrcode', PageQrcode)
        Vue.component('PageInvite', PageInvite)
        Vue.component('PageOut', PageOut)
        Vue.component('PageLoad', PageLoad)
        Vue.component('Slider', Slider)
        Vue.component('MobileTop', MobileTop)
        Vue.component('MobileHoll', MobileHoll)
        Vue.component('MobileLeft', MobileLeft)
        Vue.component('MobileBot', MobileBot)
        Vue.component('MobileQrcode', MobileQrcode)
        Vue.component('MobileQrcodeb', MobileQrcodeb)
        Vue.component('MobileInvite', MobileInvite)
        Vue.component('MobileCode', MobileCode)
        Vue.component('MobileSuc', MobileSuc)
        Vue.component('MobileOut', MobileOut)
        Vue.component('MobileLoad', MobileLoad)
        Vue.component('MobileService', MobileService)
        Vue.component('MobileSlider', MobileSlider)
    }
}

export default {
    // 导航栏
    navbar: {
        ZH: '中',
        LOGIN: '登录',
        REGISTER: '注册',
        MYDOWN: '我的下载',
        MYEQUITY: "我的权益",
        MYNUMBER: '账号安全',
        OUT: '安全退出',
        SEARCH: '请输入您想搜索的内容',
        MAX: '无限下载资格',
        SER: '搜索'
    },
    // 底部
    footer: {
        CLAUSE: '免责声明',
        TEXT: '我们没有本网站上所有试卷以及下载内容的版权，',
        VAL: '其版权均归属于各学校所有。',
    },
    // 注册
    register: {
        HOME: '注册',
        NAEM: '用户注册',
        LOGIN: '登录',
        MOBILERE: '手机号注册',
        ENTPHONE: '请输入手机号',
        ENTCODE: '请输入验证码',
        ENTPASS: '请输入密码',
        ENTPASSAGA: '请再次输入密码',
        HANDREDA: '我已阅读并同意PandaPaper熊猫搜卷',
        AGREEMENT: '《用户协议》',
        PRIVACY: '《隐私协议》',
        AND: '和',
        HELLO: '你好',
        WELCOME: '欢迎来到',
        PANDA: '熊猫搜卷',
        VALLIA: '完成登录',
        VALLIB: '添加助理获取邀请码',
        VALLIC: '填写邀请码',
        VALLID: '获取永久下载权限',
        CODENAME: '获取验证码',
        CODESY: '剩余',
        CODES: 'S',
        SUCTIT: "恭喜您，注册成功",
        SUCTEXT: '温馨提示：您可以前往【个人中心】完成绑定邮箱 修改用户昵称、修改密码等操作',
        SUCSTATR: '开始使用',
        NOTICEA: '温馨提示：您可以前往',
        NOTICEB: '个人中心',
        NOTICEC: '完成 绑定邮箱、修改用户昵称、修改密码等操作',
        NOTICED: '账号注册',
        NOTICEE: '注册并登录',
        NOTICEF: '已有账号？ 去登录',
    },
    // 登录
    login: {
        NAME: '登录',
        HOME: '用户登录',
        LOGIN: '注册',
        SMS: '短信登录',
        ACCOUNT: '账号登录',
        EMAIL: '邮箱登录',
        TEXT: "未注册过的手机号将自动注册为会员",
        USERNAME: '请输入账号',
        USERPAW: '请输入密码',
        ZDLOGIN: '下次自动登录',
        FORGOT: '忘记密码',
        ENTEMAIL: '请输入邮箱',
        FORNAME: '找回密码',
        AGAPAW: '确认密码',
        AGREEMENT: '请勾画协议'
    },
    // 首页
    index: {
        Home: '首页',
        BANNERA: '为孩子下载对应的试卷吧!',
        BANNERB: '（∞无限下载）',
        BANNERC: '注册登录开始免费搜卷下载!',
        BANNERD: '填写邀请码',
        BANNERF: '（即可∞无限下载）',
        BANNERG: '新用户注册',
        TEXTBTN: '查找试卷',
        SCHOOLBTN: "查看",
        DOWNBTN: "下载",
        SELECTA: '请选择年份',
        SELECTB: '请选择年级',
        SELECTC: '请选择科目',
        SELECTD: '请选择阶段',
        SELECTE: '请选择学校',
        TITA: "热门下载",
        TITB: "发现试卷",
        TITC: "热门学校",
        TITD: '相关推荐',
        uplodeA: "上传时间",
        uplodeB: "次查看",
        uplodeC: "次下载",
        SPAPE: "上一页",
        LPAPE: '下一页',
        TEXTNAME: '查找试卷',
        NEWSSER: '查找',
        LATESTNEWS: '最新资讯',
        DOWNBTNB: '再次下载',
        PROMPT: '提示',
        EXITACCOUNT: '确定要退出当前账号吗？',
        LOGOUT: '退出登录',
        BOTEXIT: '暂不退出',
        INVITE: '填写您的邀请码',
        NOINVITE: "还没有邀请码？ 去获得",
        SCAN: '扫描二维码添加客服',
        ALREADY: "已有邀请码？去填写",
        SECA: '为您查找到',
        SECB: '的相关试卷结果如下',
        CONUS: '联系我们',
        ISYH: '切换到英文',
        CONTACT: '联系小助理',
        GETINVITA: '获得邀请码开始下载',
        GETINVITB: '获得邀请码，查看和下载完整试卷',
        DOWNQXA: '您暂时还未获得下载权限',
        DOWNQXA: '请联系小助理获得权限',
        GOWE: '前往WeChat添加',
        GOWHAT: '打开WhatsApp',
        SERVI: "联系客服",
        SAVEER: "长按保存二维码添加小助理",
        GETWA: "打开WhatsApp联系小助理",
        LOADA: '正在下载中...',
        LOADB: '下载失败',
        LOADC: '网络过慢下载失败，请重新下载',
        QRA: '扫描二维码或',
        QRB: '点击这里',
        QRC: '添加小助理',
    },
    // 个人中心
    personal: {
        HOME: '个人中心',
        eligi: '无限下载资格',
        TEXT: '您暂时还未获得下载权限快去',
        INVITE: '获取邀请码',
        NOVALA: '扫码添加小助理获取邀请码',
        NOVALB: '填写邀请码',
        NOVALC: '获得永久下载权限',
        GODOWN: "立即去下载",
        LABELA: '密码设置',
        LABELB: '手机号修改',
        LABELC: '绑定邮箱',
        PASSA: "原密码",
        PASSB: "新密码",
        PASSC: "确认新密码",
        NICKNAME: '修改昵称',
        SAVE: '保存修改',
        FORGOT: "忘记密码?",
        MOBILE: '手机号',
        MOBILEB: '手机号码',
        VERCODE: '验证码',
        NEWPAW: '新密码',
        CONPAW: '确认新密码',
        CODE: '获取验证码',
        ENTPHONE: '请输入手机号',
        ENTCODE: '请输入验证码',
        ENTEMAIL: '请输入邮箱',
        MODIFY: '修改',
        EMAIL: '邮箱',
        NOTSET: '未设置',
        MODAVA: '修改头像',
        NOTEXT: '暂无下载内容',
        NOTEXTB: '暂无搜索结果',
        EQUITY: '我的权益',
        MYUSERA: '我的下载',
        MYUSERB: '账号安全',
        MYUSERC: '联系客服',

    },
    moblogin: {
        MYDOWN: "我的下载",
        ACCOUNT: '账号安全',
        PASSWORD: '密码设置',
        MOBIL: '手机号修改',
        EMILBD: '邮箱绑定',
        PLEAPASA: '请输入原密码',
        PLEAPASB: '请输入新密码',
        PLEAPASC: '请再次输入新密码 ',
        FORGOT: "忘记旧密码",
        SAVEXG: '保存修改 ',
        MOBDQ: '当前手机号',
        EMAILDQ: '当前邮箱',
        SEVE: '竭诚为您服务',
        SAVE: '保存',
        DXLOGIN: '短信登录',
        ZHLOGIN: '账号登录',
        YXLOGIN: '邮箱登录',
        LOGRES: '登录/注册',
        NEWRES: '新用户注册',
        TEXTXQ: '试卷详情',
        TEXTSER: '试卷快搜',
        TEXTYER: '试卷年份',
        TEXTLEVE: '适用年级',
        TEXTSUB: '试卷科目',
        TEXTJD: '试卷阶段',
        TEXTXX: '试卷学校',
        TEXTXZ: '选择标签搜索你想查找的试卷',
        TEXTDX: '支持多选，请选择试卷标签',
        SELED: '已选',
        COMP: '选择完成',
        USER: '用户名',
        MODAVA: '点击修改头像',
        MODNAME: '修改用户名',
        CODEINVI: '恭喜您，邀请码填写成功',
        GETDOWN: '您已获取无限下载次数',
        DOWNNOW: '立即开始下载',
        USEINFO: '个人信息',
        TEXTNOVAL: '暂无搜索结果~',
        TEXTNOTEXT: '暂无下载内容~',
        OUTTIT: '温馨提示',
        OUTVAL: '您确定要退出该账号吗？',
        OUTY: '退出',
        OUTN: '取消',
        MORE: '加载更多',
        KNOW: '我知道了',
        NEWXQ: '资讯详情'
    }
}


import axios from '../utils/axios'
import apiConfig from '../utils/api.config'

axios.defaults.baseURL = apiConfig.baseURL

// 获取试题列表
export function getText(data) {
    return axios({
        method: 'POST',
        url: "/api/testPaper/testpaper_list",
        data
    })
}
// 获取试题分类
export function getCate(data) {
    return axios({
        method: 'POST',
        url: "/api/testPaper/category",
        data
    })
}
// 获取热门学校
export function getHotschool(data) {
    return axios({
        method: 'POST',
        url: "/api/testPaper/hot_school",
        data
    })
}
// 获取热门学校
export function getDetails(data) {
    return axios({
        method: 'POST',
        url: "/api/testPaper/testpaper_info",
        data
    })
}
// 获取我的下载
export function getDown(data) {
    return axios({
        method: 'POST',
        url: "/api/client/download_list",
        data
    })
}
// 试卷下载
export function getTextDown(data) {
    return axios({
        responseType: 'blob',
        method: 'POST',
        url: "/api/testPaper/testpaper_down",
        data
    })
}
// 我的下载分类
export function getMYDownTpye(data) {
    return axios({
        method: 'POST',
        url: "/api/client/download_category",
        data
    })
}
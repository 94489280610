import { baseURL } from "@/assets/utils/api.config";
import router from "@/router";
//进入
export function entry() {
    //当前时间
    let now_timea = Date.now() //时间戳，毫米级
    localStorage.setItem('now_time', now_timea)
    //获取上一次登入时间
    let last_time = localStorage.getItem('entry_timea')
    //若没上次登录时间则写入本地存储
    if (!last_time) {
        localStorage.setItem('entry_timea', now_timea)
    }


    if (localStorage.getItem('issx') != 2) {
        let token = localStorage.getItem("token") || '';
        let data = new FormData();
        let type = 1
        let ref = decodeURIComponent((new RegExp('[?|&]' + 'ref' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || ''
        if (ref) {
            type = 2
        }
        data.append("url", ref)
        data.append("type", type)
        let res = fetch(baseURL + "api/index/record_visit", {
            headers: { 'Authorization': token },
            method: "post",
            body: data,
        }).then((r) => r.json());
        if (res.code == 200) {
            console.log(res);
        }
    }


}
//离开
export function leave() {
    // let leave_time = Math.round(Date.now() / 1000)
    // let entry_time = Math.round(localStorage.getItem('entry_timea') / 1000)
    // if (!entry_time) {
    //     return
    // }
    // if (leave_time - entry_time > 3) { //30毫米内算刷新处理
    //     localStorage.setItem('issx', 1)
    //     console.log('页面未刷新');
    //     localStorage.removeItem('entry_timea')
    // } else {
    //     console.log('页面刷新')
    //     localStorage.setItem('issx', 2)
    // }
}



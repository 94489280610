export default {
    // 导航栏
    navbar: {
        ZH: 'ZN',
        LOGIN: 'Log in',
        REGISTER: 'Register',
        MYDOWN: 'My downloads',
        MYEQUITY: "My rights",
        MYNUMBER: 'Profile',
        OUT: 'Log',
        SEARCH: 'Enter the content you want to search',
        MAX: 'Unlimited download eligibility',
        SER: 'Search'
    },
    // 底部
    footer: {
        CLAUSE: 'Disclaimer',
        TEXT: 'We do not own the copyrights of all test papers and downloaded content on this website,',
        VAL: 'and the copyrights belong to corresponding  schools.',
    },
    // 注册
    register: {
        HOME: 'Register',
        NAEM: 'User Registration',
        LOGIN: 'Log in',
        MOBILERE: 'Mobile registration',
        ENTPHONE: 'Enter your phone number',
        ENTCODE: 'Enter the verification code',
        ENTPASS: 'Enter the password',
        ENTPASSAGA: 'Enter the password again',
        HANDREDA: 'I have read and agree to the PandaTestPaper ',
        AGREEMENT: 'User Agreement',
        PRIVACY: 'Privacy Agreement',
        AND: 'and',
        HELLO: 'Hello',
        WELCOME: 'Welcome to',
        PANDA: 'Panda Test Paper',
        VALLIA: 'Complete login',
        VALLIB: 'Add assistant to obtain invitation code',
        VALLIC: 'Fill in the invitation code',
        VALLID: 'Obtain permanent download permission',
        CODENAME: 'Obtain',
        CODESY: 'Can be resent after',
        CODES: 'S',
        SUCTIT: "Congratulations!",
        SUCTEXT: 'You can go to [Profile] to modify phone number, password and email.',
        SUCSTATR: 'Start',
        NOTICEA: 'Notice:You can go to',
        NOTICEB: 'Profile',
        NOTICEC: 'to change username & password, and add email.',
        NOTICED: 'Register',
        NOTICEE: 'Register/Log in',
        NOTICEF: 'Already have an account? Log in',
    },
    // 登录
    login: {
        NAME: 'Log in',
        HOME: 'Log in',
        LOGIN: 'Register',
        SMS: 'SMS',
        ACCOUNT: 'Account',
        EMAIL: 'Email',
        TEXT: "Unregistered phone numbers will automatically register as members",
        USERNAME: 'Enter an account',
        USERPAW: 'Enter the password',
        ZDLOGIN: 'Auto login next time',
        FORGOT: 'Forgot password',
        ENTEMAIL: 'Enter your email address',
        FORNAME: 'Retrieve password',
        AGAPAW: 'Modification',
        AGREEMENT: 'Please agree to the agreement'
    },
    // 首页
    index: {
        Home: 'Home',
        BANNERA: 'Download the corresponding test paper for your child!',
        BANNERB: 'for unlimited download',
        BANNERC: 'Register and log in to start free search and download',
        BANNERD: 'Fill in the invitation code',
        BANNERF: 'for unlimited download',
        BANNERG: 'Registration',
        TEXTBTN: 'Search for test papers',
        SCHOOLBTN: "View",
        DOWNBTN: "Download",
        SELECTA: 'Select a year',
        SELECTB: 'Select a grade',
        SELECTC: 'Select a Subject',
        SELECTD: 'Select a stage',
        SELECTE: 'Select a school',
        TITA: "Top Downloads",
        TITB: "Discover test paper",
        TITC: "Hot schools",
        TITD: 'Related Recommendations',
        uplodeA: "Upload Time",
        uplodeB: "views",
        uplodeC: "downloads",
        SPAPE: "Previous page",
        LPAPE: 'Next page',
        TEXTNAME: 'Search for test papers',
        NEWSSER: 'Search',
        LATESTNEWS: 'Latest news',
        DOWNBTNB: 'Download',
        PROMPT: 'prompt',
        EXITACCOUNT: 'Are you sure you want to exit the current account?',
        LOGOUT: 'Log out of login',
        BOTEXIT: 'Not exiting temporarily',
        INVITE: 'Fill in invitation code',
        NOINVITE: "Don't have invitation code yet?",
        SCAN: 'Scan QR code to add assistant',
        ALREADY: "Already have an invitation code? Go fill in",
        SECA: 'The relevant results for',
        SECB: 'found for you are as follows',
        CONUS: 'Contact Us',
        ISYH: 'Switch to Chinese',
        CONTACT: 'Contact Assistant',
        GETINVITA: 'Get the invitation code to download',
        GETINVITB: 'Get the invitation code to view and download the full exam paper.',
        DOWNQXA: 'You have not yet gotten download permissions',
        DOWNQXA: 'Please contact the assistant to get permissions',
        GOWE: 'Add WeChat',
        GOWHAT: 'Open WhatsApp',
        SERVI: "CUSTOMER SERVICE",
        SAVEER: "Save QR code and add the assistant",
        GETWA: "Open WhatsApp to contact the assistant",
        LOADA: 'Downloading...',
        LOADB: 'Download failed',
        LOADC: 'Download failed, please try it again.',
        QRA: 'Scan the QR code or ',
        QRB: 'click here',
        QRC: 'to add assistant',
    },
    // 个人中心
    personal: {
        HOME: 'Personal Center',
        eligi: 'Unlimited download eligibility',
        TEXT: 'Follow these steps to get download permission:',
        INVITE: 'Go get the invitation',
        NOVALA: 'Scan QR code to add the assistant and obtain the invitation code.',
        NOVALB: 'Fill in the invitation code',
        NOVALC: 'Get unlimited downloads.',
        GODOWN: "Download now",
        LABELA: 'Password',
        LABELB: 'Phone number',
        LABELC: 'Email',
        PASSA: "Original password",
        PASSB: "New password",
        PASSC: "Confirm new password",
        NICKNAME: 'Modify nickname',
        SAVE: 'Save changes',
        FORGOT: "Forgot password?",
        MOBILE: 'Mobile number',
        MOBILEB: 'Mobile number',
        VERCODE: 'verification code',
        NEWPAW: 'New password',
        CONPAW: 'Confirm new password',
        CODE: 'Obtain',
        ENTPHONE: 'Enter your phone number',
        ENTCODE: 'Enter the verification code',
        ENTEMAIL: 'Enter your email address',
        MODIFY: 'Modify',
        EMAIL: 'Email',
        NOTSET: 'Not set',
        MODAVA: 'Modify',
        NOTEXT: 'There is currently no download content available',
        NOTEXTB: 'No search results currently available',
        EQUITY: 'My Rights',
        MYUSERA: 'My downloads',
        MYUSERB: 'Profile',
        MYUSERC: 'CUSTOMER SERVICE',
    },
    moblogin: {
        MYDOWN: "My downloads",
        ACCOUNT: 'Profile',
        PASSWORD: 'Password',
        MOBIL: 'Phone modification',
        EMILBD: 'Email binding',
        PLEAPASA: 'Original password',
        PLEAPASB: 'New password',
        PLEAPASC: 'Confirm new password ',
        FORGOT: "Forgot password",
        SAVEXG: 'Save changes ',
        MOBDQ: 'Mobile number',
        EMAILDQ: 'Email',
        SEVE: 'At your service',
        SAVE: 'Save',
        DXLOGIN: 'SMS login',
        ZHLOGIN: 'Account login',
        YXLOGIN: 'Email login',
        LOGRES: 'Login/Registration',
        NEWRES: 'Registration',
        TEXTXQ: 'Exam paper details',
        TEXTSER: 'Search test papers',
        TEXTYER: 'Year of paper',
        TEXTLEVE: 'Grade level',
        TEXTSUB: 'Exam subjects',
        TEXTJD: 'Exam stage',
        TEXTXX: 'Exam paper school',
        TEXTXZ: 'Filter the test paper',
        TEXTDX: 'Multi-selection for the paper labels',
        SELED: 'Selected',
        COMP: 'Completed',
        USER: 'User Name',
        MODAVA: 'Click to modify avatar',
        MODNAME: 'Modify username',
        CODEINVI: 'Congratulations, the invitation code filled in',
        GETDOWN: 'You get the unlimited downloads',
        DOWNNOW: 'Download now',
        USEINFO: 'Personal Information',
        TEXTNOVAL: 'There are currently no search results available~',
        TEXTNOTEXT: 'There is currently no download content available~',
        OUTTIT: 'prompt',
        OUTVAL: 'Log out of the account?',
        OUTY: 'Yes',
        OUTN: 'Not now',
        MORE: 'Load more',
        KNOW: 'Ok',
        NEWXQ: 'Details'
    }
}

import VueI18n from 'vue-i18n'
import Vue from "vue";
import zn from "./zh"
import en from "./en"
Vue.use(VueI18n);   // 全局注册国际化包

// 准备翻译的语言环境信息
let i18n = new VueI18n({
    locale: localStorage.getItem('language') || "zh-cn",   // 初始化中文
    messages: {
        "zh-cn": zn,
        "en-us": en
    }
});

export default i18n

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import User from '../views/home/user.vue'
import mobile_home from '../views/mobile/mobile_index.vue'
Vue.use(VueRouter)
const changePush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return changePush.call(this, location).catch(err => err);
}
import { entry } from '../assets/js/time'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // PC端
  // 注册
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/home/register.vue')
  },
  // 注册成功
  {
    path: '/registersuc',
    name: 'registersuc',
    component: () => import('../views/home/registersuc.vue')
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/home/login.vue')
  },
  // 找回密码
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../views/home/forgot.vue')
  },
  // 隐私政策
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/home/policy.vue')
  },
  // 用户协议
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/home/agreement.vue')
  },
  // 试卷查找
  {
    path: '/text',
    name: 'text',
    component: () => import('../views/home/text.vue')
  },
  // 试卷详情
  {
    path: '/textdetails/:id',
    name: 'textdetails',
    component: () => import('../views/home/textdetails.vue')
  },
  // 我的试卷详情
  {
    path: '/mydetails/:id',
    name: 'mydetails',
    component: () => import('../views/home/mydetails.vue')
  },
  // 个人中心
  {
    path: '/user',
    name: 'User',
    component: User,
    children: [
      {
        path: '/user/mydown',
        component: () => import('../views/home/user/mydown.vue'),
      },
      {
        path: '/user/myequity',
        component: () => import('../views/home/user/myequity.vue'),
      },
      {
        path: '/user/mynumber',
        component: () => import('../views/home/user/mynumber.vue'),
      }
    ]
  },
  // 新闻资讯
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/home/news.vue')
  },
  // 新闻资讯详情
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    component: () => import('../views/home/newsDetail.vue')
  },
  // 移动端
  {
    path: '/m',
    name: 'mobile_home',
    component: mobile_home,
    meta: { type: 'mobile' }
  },
  // 移动端注册
  {
    path: '/mobile_register',
    name: 'mobile_register',
    component: () => import('../views/mobile/mobile_register.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端注册成功
  {
    path: '/mobile_registersuc',
    name: 'mobile_registersuc',
    component: () => import('../views/mobile/mobile_registersuc.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端短信登录
  {
    path: '/mobile_login',
    name: 'mobile_login',
    component: () => import('../views/mobile/mobile_login.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端账号登录
  {
    path: '/mobile_loginb',
    name: 'mobile_loginb',
    component: () => import('../views/mobile/mobile_loginb.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端邮箱登录
  {
    path: '/mobile_loginc',
    name: 'mobile_loginc',
    component: () => import('../views/mobile/mobile_loginc.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端找回密码
  {
    path: '/mobile_forgot',
    name: 'mobile_forgot',
    component: () => import('../views/mobile/mobile_forgot.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端隐私政策
  {
    path: '/mobile_policy',
    name: 'mobile_policy',
    component: () => import('../views/mobile/mobile_policy.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端用户协议
  {
    path: '/mobile_agreement',
    name: 'mobile_agreement',
    component: () => import('../views/mobile/mobile_agreement.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端搜索页
  {
    path: '/mobile_search',
    name: 'mobile_search',
    component: () => import('../views/mobile/mobile_search.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端课程详情
  {
    path: '/mobile_textdetails/:id',
    name: 'mobile_textdetails',
    component: () => import('../views/mobile/mobile_textdetails.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端查找试卷页
  {
    path: '/mobile_text',
    name: 'mobile_text',
    component: () => import('../views/mobile/mobile_text.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端个人中心
  {
    path: '/mobile_user',
    name: 'mobile_user',
    component: () => import('../views/mobile/mobile_user.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端个人信息修改
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('../views/mobile/user/userinfo.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端修改用户名
  {
    path: '/revisename',
    name: 'revisename',
    component: () => import('../views/mobile/user/revisename.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端修改手机号
  {
    path: '/revisenum',
    name: 'revisenum',
    component: () => import('../views/mobile/user/revisenum.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端账号安全
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/mobile/user/account.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端密码设置
  {
    path: '/mobile_password',
    name: 'mobile_password',
    component: () => import('../views/mobile/user/mobile_password.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端邮箱绑定
  {
    path: '/mobile_emil',
    name: 'mobile_emil',
    component: () => import('../views/mobile/user/mobile_emil.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端我的下载
  {
    path: '/mobile_mydown',
    name: 'mobile_mydown',
    component: () => import('../views/mobile/user/mobile_mydown.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端课程详情
  {
    path: '/mobile_mydetails/:id',
    name: 'mobile_mydetails',
    component: () => import('../views/mobile/mobile_mydetails.vue'),
    meta: { type: 'mobile' }
  },
  // 移动端新闻资讯详情
  {
    path: '/mobile_newsDetail/:id',
    name: 'mobile_newsDetail',
    component: () => import('../views/mobile/mobile_newsDetail.vue'),
    meta: { type: 'mobile' }
  },
]
// 路由跳转滚轮到顶部
const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let concatPath = to.fullPath.substring(1)
  // 移动端访问PC端页面，需要跳转到移动端
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && to.meta.type !== 'mobile') {
    const path = to.fullPath !== '/' ? `mobile_${concatPath}` : '/m'
    next(path)
  } else {
    next();
  }
  // console.log(to.fullPath);
  // console.log(to.path);
  if (to.path == '/') {
    entry()
  }

  if (to.path == '/m') {
    entry()
  }

  if (from.fullPath) {
    document.title = '熊猫搜卷'
  }

});

export default router

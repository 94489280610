import axios from 'axios'
import router from '../../router/index'
import {
    removeStore
} from "../utils/utils";
import {
    Message
} from 'element-ui';
axios.defaults.timeout = 5000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    const token = window.localStorage.getItem('token')
    token && (config.headers.Authorization = token)
    config.headers['Accept-Language'] = localStorage.getItem('language') || "zh-cn"
    // config.headers['Content-Type'] = 'multipart/form-data'
    // config.data = {
    //     language: localStorage.getItem('language') || "en",
    //     ...config.data
    // }
    return config
}, function (error) {
    // 对请求错误做些什么
    console.log(error)
    return Promise.reject(error)
})
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const res = response.data
    // console.log(123, res);
    if (res.code == 101 || res.code == 102) {
        removeStore("token");
        removeStore("userinfo");
        Message.error(res.msg);
        router.replace({
            path: '/login'
        })
    }
    // if (res.code === 102 || res.code == 101) {
    //     removeStore("token");
    //     removeStore("info");
    //     Message.error('Please log in again');
    //     router.replace({
    //         path: '/'
    //     })
    // }
    return res
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
})
export default axios

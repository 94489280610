<template>
  <div class="con" v-if="islogin" @mousedown="$emit('update:islogin', false)">

    <div class="con_box" @mousedown.stop="$emit('update:islogin', true)">
      <div class="goblak" @click="goRegister"><a>{{$t('login.LOGIN')}}</a></div>
        <div class="log_box">
                    <div class="log_img"><img src="../../views/home/image/login.png" alt=""></div>
                    <div class="line"></div>
                    <div class="login">
                        <el-tabs v-model="activeName">
                          <el-tab-pane :label="$t('login.SMS')" name="mobile">
                              <div class="form" style="margin-top:10px">
                                  <div class="icon_box">
                                      <div class="icon"><img src="../../views/home/image/phone.png" alt=""></div>
                                      <!-- <div class="icon_text">{{val}}</div>
                                      <div class="icon_line"></div> -->
                                      <el-select v-model="val" :placeholder="val">
                                        <el-option label="+65" value="+65"></el-option>
                                        <el-option label="+86" value="+86"></el-option>
                                      </el-select>
                                  </div>
                                  <input class="form_inputsb" type="text" v-model="accountNum" :placeholder="$t('register.ENTPHONE')"/>
                              </div>
                              <div class="form">
                                  <div class="icon_box">
                                      <div class="icon"><img src="../../views/home/image/message .png" alt=""></div>
                                  </div>
                                  <input class="form_inputs" type="text" v-model="verificationCode" :placeholder="$t('register.ENTCODE')"/>
                                  <div class="code" @click="getVerificationCode">{{ sendCodeEng }}</div>
                              </div>
                              <div class="bom">
                                <div class="bom_img" @click="onCheck">
                                    <img src="../../views/home/image/cleck.png" alt="" v-if="ischeck">
                                    <img src="../../views/home/image/nocleck.png" alt="" v-else>
                                </div>
                                <div class="bom_text">{{$t('register.HANDREDA')}} <span @click="goAgree">{{$t('register.AGREEMENT')}} </span>{{$t('register.AND')}} <span @click="goPolicy">{{$t('register.PRIVACY')}}</span></div>
                                </div>
                              <div class="gobtn" @click="loginInA"><a>{{$t('login.NAME')}}</a></div>
                              <div class="login_foot">{{$t('login.TEXT')}}</div>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('login.ACCOUNT')" name="users">
                              <div class="form" style="margin-top:10px">
                                  <div class="icon_box">
                                      <div class="icon"><img src="../../views/home/image/phone.png" alt=""></div>
                                      <!-- <div class="icon_text">{{val}}</div>
                                      <div class="icon_line"></div> -->
                                      <el-select v-model="val" :placeholder="val">
                                        <el-option label="+65" value="+65"></el-option>
                                        <el-option label="+86" value="+86"></el-option>
                                      </el-select>
                                  </div>
                                  <input class="form_inputsb" type="text" v-model="accountUser" autocomplete="on" :placeholder="$t('login.USERNAME')"/>
                              </div>

                              <div class="form">
                                  <div class="icon_box">
                                      <div class="icon"><img src="../../views/home/image/cipher .png" alt=""></div>
                                  </div>
                                  <form>
                                      <input class="form_inputs" :type="pasTy" v-model="passwordNum" autocomplete="on" :placeholder="$t('login.USERPAW')" />
                                  </form>
                                  <span class="passty" :class="{passtys:ispasTy}" @click="onPasTy"></span>
                              </div>
                              <div class="bom">
                                <div class="bom_img" @click="onCheck">
                                    <img src="../../views/home/image/cleck.png" alt="" v-if="ischeck">
                                    <img src="../../views/home/image/nocleck.png" alt="" v-else>
                                </div>
                                <div class="bom_text">{{$t('register.HANDREDA')}} <span @click="goAgree">{{$t('register.AGREEMENT')}} </span>{{$t('register.AND')}} <span @click="goPolicy">{{$t('register.PRIVACY')}}</span></div>
                                </div>
                              <div class="gobtn" @click="loginInB"><a>{{$t('login.NAME')}}</a></div>
                              <div class="login_footb">
                                <div class="again_login">
                                  <div class="bom_img" @click="onCheckb">
                                  <img src="../../views/home/image/cleck.png" alt="" v-if="ischeckb">
                                  <img src="../../views/home/image/nocleck.png" alt="" v-else>
                                  </div>
                                  {{$t('login.ZDLOGIN')}}
                                </div>
                                <div class="forgot" @click="onForgot">{{$t('login.FORGOT')}}</div>
                              </div>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('login.EMAIL')" name="email">
                            <div class="form" style="margin-top:10px">
                                <div class="icon_box">
                                    <div class="icon"><img src="../../views/home/image/email.png" alt=""></div>
                                </div>
                                <form>
                                    <input class="form_inputs" type="text"  v-model="accountEmail" :placeholder="$t('login.ENTEMAIL')"/>
                                </form>
                            </div>
                            <div class="form">
                                <div class="icon_box">
                                    <div class="icon"><img src="../../views/home/image/cipher .png" alt=""></div>
                                </div>
                                <form>
                                    <input class="form_inputs" :type="pasTyb" autocomplete="off" v-model="newpasswordNum" :placeholder="$t('login.USERPAW')"/>
                                </form>
                                <span class="passty" :class="{passtys:ispasTyb}" @click="onPasTyb"></span>
                            </div>
                            <div class="bom">
                              <div class="bom_img" @click="onCheck">
                                  <img src="../../views/home/image/cleck.png" alt="" v-if="ischeck">
                                  <img src="../../views/home/image/nocleck.png" alt="" v-else>
                              </div>
                              <div class="bom_text">{{$t('register.HANDREDA')}} <span @click="goAgree">{{$t('register.AGREEMENT')}} </span>{{$t('register.AND')}} <span @click="goPolicy">{{$t('register.PRIVACY')}}</span></div>
                              </div>
                            <div class="gobtn" @click="loginInC"><a>{{$t('login.NAME')}}</a></div>
                          </el-tab-pane>
                        </el-tabs>
                    </div>
      </div>
      <!-- 验证短信码 -->
      <div v-if="show" class="valid">
        <Slider @getImg="getImg" @validImg="validImg" @close="onClose" :log="true"></Slider>
      </div>  
    </div>   
        
  </div>
</template>

<script>
import { getYzm,getPhoneCode,getLogin,getCodeLogin } from "@/assets/api/index";
import { setStore } from "@/assets/utils/utils";
import { setCookie,getCookie } from "@/assets/utils/cookie";
export default {
    props:['islogin'],
    data(){
        return{
            accountNum:"",  //手机号
            accountUser:'',    //账号
            accountEmail:"",   //邮箱
            ispasTy:false,
            pasTy:'password',
            ispasTyb:false,
            pasTyb:'password',
            verificationCode:"",  //验证码
            passwordNum:'',   //密码
            newpasswordNum:'',   //邮箱密码
            sendCodeEng: "获取验证码",
            sendNum: 60,
            val:'+65',
            isSendCode: false,
            show:false,
            istime:true,
            ischeck:false,
            activeName:'mobile',
            ischeckb:false
        }
    },
    created(){
        if(this.$i18n.locale == 'zh-cn'){ 
           this.sendCodeEng = '获取验证码'
        }else{
          this.sendCodeEng = 'Obtain'
        }
    },
    mounted(){
        if(getCookie('userName') && getCookie('password')){
          setCookie(userName,password,-1)
        }
        // setCookie(userName,password,-1)
        // let Base64 = require('js-base64').Base64
        // if(getCookie('userName')){
        //   this.ischeckb = true
        //   this.accountUser =Base64.decode(getCookie('userName'))
        // }
        // if(getCookie('userPwd')){
        //   this.passwordNum =Base64.decode(getCookie('userPwd')) 
        // }  
        if(sessionStorage.getItem('yatimea')){
            let startTime = sessionStorage.getItem('yatimea')
            console.log(startTime,+new Date() );
            let diffTime =parseInt((+new Date() - startTime) / 1000) 
            console.log(diffTime);
            if(diffTime<60){
            this.show=false
            this.sendNum = 60 - diffTime  
            this.istime = false;
            this.timer = setInterval(() => { 
                if (this.sendNum > 0 && this.sendNum <= 60) {
                this.sendNum--;
                this.sendCodeEng = this.sendNum + this.$t('register.CODES');
                } else {
                clearInterval(this.timer); // 清除定时器
                this.istime = true;
                this.sendCodeEng =this.$t('register.CODENAME')
                }
            }, 1000);
            }else{
            sessionStorage.removeItem('yatimea')
            }
            
        }
    },
    methods:{
        oncon(){},
        goAgree(){
          this.$router.push('/agreement')
        },
        goPolicy(){
          this.$router.push('/policy')
        },
        async getImg(callback) {
            const res = await getYzm({});
            if (res.code==200) {
                callback(res.data);
            }else{
                this.$message({
                    type: "error",
                    message: res.msg,
                });
            }
          },
        validImg(movePercent, id, callback) {
          this.getPhoneCode(id,movePercent* 240,callback)
        },
        onClose() {
          this.show = false;
        },
 
        onPasTy(){
            this.ispasTy=!this.ispasTy
            if(this.ispasTy){
                this.pasTy="text"
            }else{
                this.pasTy="password"
            }
        },
        onPasTyb(){
            this.ispasTyb=!this.ispasTyb
            if(this.ispasTyb){
                this.pasTyb="text"
            }else{
                this.pasTyb="password"
            }
        },
        onCheck(){
            this.ischeck =!this.ischeck
        },
        onCheckb(){
            this.ischeckb =!this.ischeckb
        },

        onTab(){
          if(this.activeName='mobile'){
            this.accountUser = ''
            this.passwordNum = ''
            this.accountEmail = ''
            this.newpasswordNum = ''
          }else if(this.activeName='users'){
            this.accountNum = ''
            this.verificationCode = ''
            this.accountEmail = ''
            this.newpasswordNum = ''
          }else{
            this.accountNum = ''
            this.verificationCode = ''
            this.accountUser = ''
            this.passwordNum = ''
          }
        },

        // 手机验证码按钮
        getVerificationCode() {
          if(!this.istime){
            return
          }
          if (this.accountNum == "") {
            this.$message({
              type: "error",
              message: this.$t('register.ENTPHONE'),
            });
            return;
          }
          if (this.sendCodeEng == this.$t('register.CODENAME')) {
            this.show=true
          }
        },
        //获取手机验证码
        async getPhoneCode(id,movePercent,callback) {
          const res = await getPhoneCode({
            id:id,
            offset:movePercent,
            mobile: this.val+this.accountNum,
          });

          if (res.code==200) {
            this.show=false
            this.isSendCode = true;
            if (this.isSendCode == true) {
              this.sendCodeEng = this.sendNum + this.$t('register.CODES');
              this.timer = setInterval(() => {
                this.sendNum--;
                this.sendCodeEng = this.sendNum + this.$t('register.CODES');
                sessionStorage.setItem('yatimea', +new Date())
                if (this.sendNum < 0) {
                  clearInterval(this.timer);
                  this.sendCodeEng = this.$t('register.CODENAME');
                  this.sendNum = 60;
                  this.isSendCode = false;
                  sessionStorage.removeItem('yatimea')
                }
              }, 1000);
            }
          } else {
            callback(false)
          }
        },
        // 手机号登录按钮
        loginInA(){
         if (this.accountNum == "") {
            this.$message({
              type: "error",
              message: this.$t('register.CODENAME'),
            });
            return;
          }
          if (this.verificationCode == "") {
            this.$message({
              type: "error",
              message: this.$t('register.ENTCODE'),
            });
            return;
          }
          if (!this.ischeck) {
            this.$message({
              type: "error",
              message: this.$t('login.AGREEMENT'),
            });
            return;
          }
          this.getCodeLogin()
        },
        // 账号登录按钮
        loginInB(){
         if (this.accountUser == "") {
            this.$message({
              type: "error",
              message: this.$t('login.USERNAME'),
            });
            return;
          }
          if (this.passwordNum == "") {
            this.$message({
              type: "error",
              message: this.$t('login.USERPAW'),
            });
            return;
          }
          if (!this.ischeck) {
            this.$message({
              type: "error",
              message: this.$t('login.AGREEMENT'),
            });
            return;
          }
          this.getLogin()
        },
        // 邮箱登录按钮
        loginInC(){
         if (this.accountEmail == "") {
            this.$message({
              type: "error",
              message: this.$t('login.ENTEMAIL'),
            });
            return;
          }
          if (this.newpasswordNum == "") {
            this.$message({
              type: "error",
              message: this.$t('login.USERPAW'),
            });
            return;
          }
          if (!this.ischeck) {
            this.$message({
              type: "error",
              message: this.$t('login.AGREEMENT'),
            });
            return;
          }
          this.getEmailLogin()
        },
        //手机号验证码登录
        async getCodeLogin() {
          const res = await getCodeLogin({
            // username: `+91${this.accountNum}`,
            username: this.val+this.accountNum,
            verify_code: this.verificationCode,
          });
          console.log(res);
          if (res.code==200) {
            this.userToken = res.data.token;
            setStore("token", this.userToken);
            setStore('userinfo',res.data)
            this.$store.commit('setUserInfo',res.data)
            this.$message({
              type: "success",
              message: res.msg,
            });
            setTimeout(() => {
              this.$router.go(0)
            }, 700);
          } else {
            setTimeout(() => {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }, 500);
            return;
          }
        },
        //账号登录
        async getLogin() {
          const res = await getLogin({
            // username: `+91${this.accountNum}`,
            username: this.val+this.accountUser,
            password: this.passwordNum,
          });
          console.log(res);
          if (res.code==200) {
            this.userToken = res.data.token;
            setStore("token", this.userToken);
            setStore('userinfo',res.data)
            this.$store.commit('setUserInfo',res.data)
            // let Base64 = require('js-base64').Base64
            // let userName = Base64.encode(this.accountUser)
            // let password = Base64.encode(this.passwordNum)
            // if(this.ischeckb){
            //   setCookie(userName,password,7)
            // }else{
            //   setCookie(userName,password,-1)
            // }
            this.$message({
              type: "success",
              message: res.msg,
            });
            setTimeout(() => {
              this.$router.go(0)
            }, 700);
          } else {
            setTimeout(() => {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }, 500);
            return;
          }
        },
        //邮箱登录
        async getEmailLogin() {
          const res = await getLogin({
            username: this.accountEmail,
            password: this.newpasswordNum,
          });
          console.log(res);
          if (res.code==200) {
            this.userToken = res.data.token;
            setStore("token", this.userToken);
            setStore('userinfo',res.data)
            this.$store.commit('setUserInfo',res.data)
            this.$message({
              type: "success",
              message: res.msg,
            });
            setTimeout(() => {
              this.$router.go(0)
            }, 700);
          } else {
            setTimeout(() => {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }, 500);
            return;
          }
        },
        goRegister(){
          this.$router.push({path: "/register"});
        },
        onForgot(){
          this.$router.push({path: "/forgot"});
        }

    }
}
</script>

<style lang='less' scoped>
::v-deep .el-input--suffix .el-input__inner{
  width: 60px;
  border: none;
  padding: 0;
  font-size: 16px;
  color: #333333;
  font-weight: 600;
}
.login_footb{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 40px auto 0;
  font-size: 14px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #666666;
}
.again_login{
  display: flex;
}
.forgot{
  cursor: pointer;
}
.forgot:hover{
  color: #2655F0;
}
.valid{
  width: 200px;
  height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.con{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9;
}

.con_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 800px;
    height: 600px;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding-top: 96px;
    box-sizing: border-box;
}
.goblak{
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    height: 60px;
    background: #5A85F8;
    border-radius: 0px 16px 0px 8px;
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
}
.goblak a{
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #FFFFFF;
}
.goblak:hover{
    background: #2655F0;
}
.log_box{
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    display: flex;
}
.log_img{
    width: 300px;
    height: 308px;
    margin-right: 20px;
    margin-top: 40px;
}
.log_img img{
    width: 100%;
    height: 100%;
}
.line{
    width: 1px;
    height: 440px;
    background-color: #CCCCCC;
    margin-right: 48px;
}
.login_text{
    font-size: 24px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #333333;
    margin-bottom: 32px;
}
.form{
    position: relative;
    margin-bottom: 15px;
}
input{
    width: 320px;
    height: 48px;
    border-bottom: 1px solid #E5E5E5;
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #333333;
}
input::placeholder{
    color: #999999;
    font-weight: normal;
}
.form_inputsb{
    // padding-left: 77px;
    padding-left: 85px;
    box-sizing: border-box;
}
.form_inputs{
    padding-left: 36px;
    box-sizing: border-box;
}
.icon_box{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}
.icon img{
    width: 24px;
    height: 24px;
}
.icon_text{
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #333333;
    margin-right: 8px;
}
.icon_line{
    width: 1px;
    height: 16px;
    background-color: #CCCCCC;
}
.passty{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  background: url('../../views/home/image/close.png') no-repeat center;
}
.passtys{
  background: url('../../views/home/image/open.png') no-repeat center;
}
.code{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #2655F0;
    cursor: pointer;
}
.code:hover{
    color: #5A85F8;
}
.bom{
    display: flex;
    margin-top: 30px;
}
.bom_img{
    margin-right: 8px;
}
.bom_img img{
    width: 16px;
    height: 16px;
}
.bom_text{
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
    width: 296px;
}
.bom_text span{
    color: #2655F0;
}
.gobtn{
    width: 320px;
    height: 48px;
    background: linear-gradient(180deg, #85ADFF 1%, #2655F0 99%);
    box-shadow: 4px 8px 20px 0px rgba(38,85,240,0.3);
    border-radius: 30px 30px 30px 30px;
    margin-top: 30px;
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
}
.gobtn a{
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #FFFFFF;
}
.gobtn:hover{
    box-shadow: 4px 8px 20px 0px rgba(38,85,240,0.502);
}
.login_foot{
  font-size: 14px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin: 40px auto 0;
  text-align: center;
  width: 260px;
}
::v-deep .el-tabs__nav-wrap::after{
  width: 0;
}

::v-deep .el-tabs__item{
  font-size: 20px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #666666;
}
::v-deep .el-tabs__item:hover{
  font-size: 20px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #333333;
}
::v-deep .el-tabs__item.is-active{
  font-size: 20px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #333333;
}
::v-deep .el-tabs__active-bar{
  width: 40px !important;
  background-color: #2655F0;
}
</style>
<template>
  <div>
    <div id="onTop" class="topp"></div>
    <page-top />

    <div class="user">
        <div class="user_con">
            <div class="bread">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('index.Home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('personal.HOME')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="user_ban">
                <div class="user_avatar"  @mouseover="isavatar=true" @mouseleave="isavatar=false">
                    <!-- <img src="../../assets/img/avatarb.png" alt=""> -->
                    <img :src="UserInfo.avatar" alt="">
                    <div class="uplodeimg" @click="Editava" v-if="isavatar">{{$t('personal.MODAVA')}}</div>
                    <form enctype='multipart/form-data' class="noform">
                        <input type="file" @change="upload" ref="file" enctype="multipart/form-data">
                    </form>
                    
                </div>
                <div class="user_info">
                    <div class="user_name" @mouseover="isnames=true" @mouseleave="isnames=false">
                        {{UserInfo.nickname}} 
                        <span @click="isname=true" v-if="isnames">{{$t('personal.MODIFY')}}</span>
                    </div>
                    <div class="user_zg" v-if="UserInfo.is_vip==1"><img src="./image/sun.png" alt=""> {{$t('personal.eligi')}}</div>
                </div>
            </div>
            <div class="user_box">
                <div class="user_type">
                    <router-link :to="item.link" class="type_li type_lia" :class="{typeClass:istype==index}" v-for="(item,index) in typeList" :key="index" @click.native="onType(index)">
                        <div class="type_i" :class="{iClass:istype==index}">
                            <div class="lia">
                                <img :src="item.usera" alt="" v-if="istype==index">
                                <img :src="item.userb" alt="" v-else>
                            </div>
                            {{item.name}}
                        </div>
                    </router-link>
                    <!-- <router-link to="/user/myequity" class="type_li type_lib"><div class="type_i"><div class="lib"></div>我的下载</div></router-link>
                    <router-link to="/user/mynumber" class="type_li type_lic"><div class="type_i"><div class="lic"></div>我的下载</div></router-link> -->
                </div>
                <div class="user_sub">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>

    <!-- 修改昵称弹窗 -->
    <div class="inviteDialog" v-if="isname">
        <div class="inviteDialog_box">
            <div class="invite_title">{{$t('personal.NICKNAME')}}</div>
            <input class="nameinput" type="text" v-model="nameinput"/>
            <div class="invite_btn" @click="onRename">
                    <img src="./image/sucbtn.png" alt="" v-if="nameinput.length">
                    <img src="./image/nobtn.png" alt="" v-else>
            </div>
        </div>
        <div class="inviteclose" @click="isname=false"><img src="./image/inviteclose.png" alt=""></div>
    </div>

    <page-bot />
  </div>
</template>

<script>
import { getEditInfo,getInfo,getUpload } from "@/assets/api/index";
import { setStore } from "@/assets/utils/utils";
export default {
    name:'User',
    data(){
        return{
           istype:this.$store.state.typeIndex||0,
           policyData:'',
           typeList:[
            {
            link:'/user/mydown',
            name:'我的下载',
            usera:require('./image/usertypeb.png'),
            userb:require('./image/usertypea.png')
            },
            {
            link:'/user/myequity',
            name:'我的权益',
            usera:require('./image/usertyped.png'),
            userb:require('./image/usertypec.png')
            },
            {
            link:'/user/mynumber',
            name:'账号安全',
            usera:require('./image/usertypef.png'),
            userb:require('./image/usertypee.png')
            },
           ],
           isname:false,
           nameinput:'',
           UserInfo:[],
           avatar:'',
           isavatar:false,
           isnames:false
        }
    },
    created(){
        this.getInfo()
        if(this.$i18n.locale == 'zh-cn'){ 
           this.typeList[0].name = '我的下载'
           this.typeList[1].name = '我的权益'
           this.typeList[2].name = '账号安全'
        }else{
           this.typeList[0].name = 'My downloads'
           this.typeList[1].name = 'My rights'
           this.typeList[2].name = 'Profile'
        }
    },
    mounted(){
        let isurlt =window.location.href.substr(-6)
        if(isurlt=='equity'){
            this.istype = 1
        }else if(isurlt=='number'){
            this.istype = 2
        }else{
            this.istype = 0
        }
        console.log(isurlt);
    },
    methods:{
        async getInfo(){
            const res = await getInfo({});
            console.log(res);
          if (res.code==200) {
            this.UserInfo = res.data
            setStore('userinfo',this.UserInfo)
            this.$store.commit('setUserInfo',res.data)
            this.nameinput = res.data.nickname
            this.avatar = res.data.avatar
            console.log(this.$store.state.userInfo);
          } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            return;
          }
        },
        onBack(){
            this.$route.push('./')
        },

        onType(index){
            this.istype = index
        },
        async onRename(){
            if(!this.nameinput){
                this.$message({
                type: "error",
                message: '请输入姓名',
              });
              return
            }
           this.onEditInfo()
        },

        Editava(){
            this.$refs.file.click()
        },

        async upload(){
            var formData = new FormData()
            formData.append( 'file',this.$refs.file.files[0])
            const res = await getUpload(formData);
            console.log(res);
          if (res.code==200) {
              this.avatar = res.data.url
              this.onEditInfo()
          } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            return;
          }
        },

         async onEditInfo(){
            const res = await getEditInfo({
                nickname:this.nameinput,
                avatar:this.avatar
            });
            if (res.code==200) {
                console.log(res);
                this.$message({
                type: "success",
                message: res.msg,
                });
                this.getInfo()
                setTimeout(() => {
                window.location.reload()
                }, 1000);
            }else{
                this.$message({
                type: "error",
                message: res.msg,
              });
            }
        },
    }
}
</script>

<style lang='less' scoped>
::v-deep .el-breadcrumb__inner.is-link:hover{
    color: #2655F0;
}
.nameinput{
    width: 320px;
    height: 56px;
    background: #FAFAFA;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #CCCCCC;
    margin-bottom: 28px;
    font-size: 28px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #333333;
    padding-left: 10px;
    box-sizing: border-box;
}
.inviteDialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9;
}
.inviteDialog_box{
    width: 500px;
    height: 360px;
    background: url('./image/invite.png') no-repeat;
    margin: 320px auto 0;
    padding-top: 52px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.invite_title{
    width: 100%;
    font-size: 36px;
    font-family: alibaba-font;
    font-weight: 900;
    color: #333333;
    line-height: 36px;
    text-align: center;
    margin-bottom: 28px;
}
.inviteclose{
    width: 30px;
    height: 30px;
    margin: 24px auto 0;
    cursor: pointer;
}
.invite_btn{
    width: 100px;
    height: 100px;
    cursor: pointer;
}


.topp{
    position: absolute;
    top: 0;
}
.user{
    width: 100%;
    margin-top: 110px;
}
.user_con{
    width: 1200px;
    margin: 20px auto 60px;
}
.user_ban{
    width: 100%;
    height: 120px;
    background: url('./image/userban.png') no-repeat 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    box-sizing: border-box;
    margin: 20px 0 30px;
}
.user_avatar{
    position: relative;
    width: 70px;
    height: 70px;
    margin-right: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.user_avatar img{
    width: 100%;
    height: 100%;
}
.noform{
    display: none;
}
.uplodeimg{
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
}
.user_name{
    font-size: 24px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.user_name span{
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    margin-left: 20px;
    cursor: pointer;
}
.user_name span:hover{
    color: #2655F0;
}
.user_zg{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #999999;
}
.user_zg img{
    width: 25px;
    height: 28px;
    margin-right: 5px;
}
.user_box{
    width: 1200px;
    display: flex;
    justify-content: space-between;
}
.user_type{
    width: 240px;
    height: 260px;
    background: #FFFFFF;
    padding: 12px 0px;
    box-sizing: border-box;
}
.type_li{
    display: block;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.type_i{
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    // justify-content: center;
    border-left: 6px solid #FFFFFF;
    border-right: 6px solid #FFFFFF;
    box-sizing: border-box;
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #666666;

}
.type_li:hover{
    background-color: #F2F6FC;
}
.typeClass{
    background-color: #F2F6FC;
}
.iClass{
    border-left: 6px solid #2655F0;
    border-right: 6px solid #F2F6FC;
}

.type_li:hover .type_i{
    border-left: 6px solid #2655F0;
    border-right: 6px solid #F2F6FC;
}
// .type_lia:hover .lia{
//     background: url('./image/usertypeb.png') no-repeat;
// }
// .type_lib:hover .lib{
//     background: url('./image/usertyped.png') no-repeat;
// }
// .type_lic:hover .lic{
//     background: url('./image/usertypef.png') no-repeat;
// }
.lia{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.lia img{
    width: 100%;
    height: 100%;
}
// .lia{
//     width: 30px;
//     height: 30px;
//     background: url('./image/usertypea.png') no-repeat;
//     margin-right: 10px;
// }
// .lib{
//     width: 30px;
//     height: 30px;
//     background: url('./image/usertypec.png') no-repeat;
//     margin-right: 10px;
// }
// .lic{
//     width: 30px;
//     height: 30px;
//     background: url('./image/usertypee.png') no-repeat;
//     margin-right: 10px;
// }

.user_sub{
    width: 930px;
    min-height: 600px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 8px 8px 30px 0px rgba(188,193,204,0.1);
}
</style>
<template>
    <div class="bot">
        <div class="botbox">
            <div class="bottop">
                <div class="bot_le">
                    <div class="logo"><img src="../../views/mobile/image/logob.png" alt=""></div>
                    <div class="bot_tit">{{$t('footer.CLAUSE')}}：</div>
                    <div class="bot_text">{{$t('footer.TEXT')}} {{$t('footer.VAL')}}</div>
                </div>
                <div class="bot_rg"><img src="../../views/home/image/panda.png" alt=""></div>
            </div>
            <div class="bot_bom">MJS SGP BRANCH PTE.LTD 版权所有</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
         
        }
    },
    created(){
       
        
    },
    methods:{

    }
}
</script>

<style lang='less' scoped>
// 吸底
.bot{
  width: 100%;
  background-color: #31353D;
}
.botbox{
    width: 100%;
    padding: 10px 20px 0;
    box-sizing: border-box;
}
.bottop{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.logo img{
    width: 190px;
    height: 44px;
}
.bot_tit{
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #FFFFFF;
    margin: 4px 0 8px;
}
.bot_text{
    font-size: 12px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #CCCCCC;
    line-height: 18px;
    width: 220px;
}
.bot_rg img{
    width: 68px;
    height: 81px;
}
.bot_bom{
    margin-top: 16px;
    width: 100%;
    height: 46px;
    border-top: 1px solid #4D4D4D;
    text-align: center;
    line-height: 46px;
    font-size: 10px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    color: #808080;
}
</style>
import axios from '../utils/axios'
import apiConfig from '../utils/api.config'

axios.defaults.baseURL = apiConfig.baseURL

// 获取新闻列表
export function getNewsList(data) {
    return axios({
        method: 'POST',
        url: "/api/news/news_list",
        data
    })
}

// 获取新闻详情
export function getNewsInfo(data) {
    return axios({
        method: 'POST',
        url: "/api/news/news_info",
        data
    })
}
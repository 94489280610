<template>
    <!-- 吸顶容器 -->
    <div class="top">
        <div class="top_box">
            <div class="logo" @click="goback"><img src="../../views/mobile/image/logo.png" alt=""></div>
            <div class="top_rg">
                <div class="login" v-if="usetoken" @click="goUser">
                    <img :src="userinfo.avatar" alt="">
                </div>
                <div class="nouser" @click="goLogin" v-else>
                    <img src="../../views/mobile/image/avatar.png" alt="">
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import { getStore,removeStore,setStore } from "@/assets/utils/utils";
import { getLoginOut,getInfo } from "@/assets/api/index";
export default {
  data() {
    return {
        userinfo:this.$store.state.userInfo||'',
        usetoken:getStore('token'),
    };
  },
  mounted(){
    if(this.usetoken){
        this.getInfo()
    }
  },
  methods: { 
    goback(){
        this.$router.push({path: "/"});
        sessionStorage.removeItem('TextList')
        sessionStorage.removeItem('NameList')
    },
    goLogin(){
        this.$router.push('/mobile_login')
    },
    goUser(){
        this.$router.push('/mobile_user')
    },
    async getInfo(){
        const res = await getInfo({});
        if (res.code==200) {
            setStore('userinfo',res.data)
        }
    },

  },
};
</script>
<style lang='less' scoped>
// 吸顶
.top{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 44px;
  background-color: #FFFFFF;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #E5E5E5;
}
.top_box{
    width: 100%;
    height: 44px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo img{
    width: 190px;
    height: 44px;
}
.login{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.login img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform:scale(0.5);
}
.nouser{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
}
.nouser img{
    width: 100%;
    height: 100%;
}

</style>

<template>
  <!-- 邀请码弹窗 -->
<div>
  <div class="inviteDialog" v-if="isinvitesuc" @mousedown="$emit('update:isinvitesuc', false)">
        <div class="inviteDialog_box" :class="{inviteDialog_boxb:$t('navbar.ZH')=='ZN'}" @mousedown.stop="$emit('update:isinvitesuc', true)">
           <img src="../../views/mobile/image/suc.png" alt="">
           <div class="titlescu" :class="{titlescub:$t('navbar.ZH')=='ZN'}">{{$t('moblogin.CODEINVI')}} <br /> {{$t('moblogin.GETDOWN')}}</div>
           <div class="sucbtn" @click="goBack">{{$t('moblogin.DOWNNOW')}}<div></div></div>
        </div>
        <div class="inviteclose"><img src="../../views/home/image/inviteclose.png" alt=""></div>
    </div>
    <!-- <page-qrcode :isqrcode.sync="isqrcode"/> -->
</div>
  

</template>

<script>

export default {
    props:['isinvitesuc'],
    data(){
        return{

        }
    },
    methods:{
        goBack(){
            this.$emit('update:isinvitesuc', false)
            this.$router.push('/m')
        }

    }
}
</script>

<style lang='less' scoped>

.inviteDialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9;
}
.inviteDialog_box{
    width: 300px;
    height: 304px;
    background: url('../../views/mobile/image/invite.png') no-repeat;
    background-size: 300px 304px;
    margin: 120px auto 0;
    padding-top: 56px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.inviteDialog_boxb{
    width: 300px;
    height: 304px;
    background: url('../../views/mobile/image/invite.png') no-repeat;
    background-size: 300px 304px;
    margin: 120px auto 0;
    padding-top: 36px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.inviteDialog_box img{
    width: 64px;
    height: 64px;
}
.titlescu{
    width: 210px;
    font-size: 18px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
    margin: 16px 0 32px;
}
.titlescub{
    margin-bottom: 20px;
}
.sucbtn{
    width: 200px;
    height: 40px;
    background: linear-gradient(180deg, #85ADFF 1%, #2655F0 99%);
    box-shadow: 2px 4px 10px 0px rgba(38,85,240,0.3);
    border-radius: 24px 24px 24px 24px;
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
}
.sucbtn div{
    background: url('../../views/mobile/image/downb.png') no-repeat;
    background-size: 12px 12px;
}
.inviteclose{
    width: 30px;
    height: 30px;
    margin: 20px auto;
}
</style>
<template>
    <!-- 吸顶容器 -->
    <div class="bot">
        <div class="botbox">
            <div class="bottop">
                <div class="bot_le">
                <div class="logo"><img src="../../views/home/image/logob.png" alt=""></div>
                <div class="bot_tit">{{$t('footer.CLAUSE')}}：</div>
                <div class="bot_text">{{$t('footer.TEXT')}} <br /> {{$t('footer.VAL')}}</div>
                </div>
                <div class="bot_rg"><img src="../../views/home/image/panda.png" alt=""></div>
            </div>
            <div class="bot_bom">MJS SGP BRANCH PTE.LTD 版权所有</div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  mounted(){

  },
  methods: {
    
  },
};
</script>
<style lang='less' scoped>
// 吸底
.bot{
  width: 100%;
  height: 260px;
  background-color: #31353D;
}
.botbox{
    width: 100%;
    padding-top: 40px;
    box-sizing: border-box;
}
.bottop{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.logo{
    width: 350px;
    height: 40px;
    margin-bottom: 24px;
}
.bot_tit{
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 8px;
}
.bot_text{
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #CCCCCC;
    line-height: 22px;
}
.bot_rg{
    width: 118px;
    height: 140px;
}
.bot_bom{
    width: 100%;
    height: 48px;
    border-top: 1px solid #4D4D4D;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #808080;
}
</style>

<template>
  <div>
    <div class="con" v-if="isqrcode">
        <div class="con_box">   
            <div class="qrcode_top">
                <div class="qrtit">{{$t('index.CONTACT')}}</div>
                <div class="qrval">{{$t('index.GETINVITA')}}</div>
            </div>
            <div class="qrtype">
                <span v-for="(item,index) in typeList" :key="index" :class="{isqrspan:isqrspan==index}" @click="onTypeb(index)">{{item.name}}</span>
            </div>
            <div class="qrerwm">
                <div class="wechat" v-if="!isqrspan">
                    <img :src="wxqrcode" alt="" >
                    <div class="qrcode_scan"><img src="../../views/mobile/image/scan.png" alt="">{{$t('index.SAVEER')}}</div>
                    <div class="qrbtn" @click="goWeChat">{{$t('index.GOWE')}}</div>
                </div>
                <div class="whats" v-else>
                    <img src="../../views/mobile/image/Whats.png" alt="" >
                    <div class="qrcode_scan"><img src="../../views/mobile/image/wechat.png" alt="">{{$t('index.GETWA')}}</div>
                     <div class="qrbtn" @click="goWhatApp">{{$t('index.GOWHAT')}}</div>
                </div>
                
            </div>

            <div class="qrcode_bom" @click="goInvite">{{$t('index.ALREADY')}}<img src="../../views/mobile/image/rg.png" alt=""></div>    
              
        </div>   
        <div class="qrcodeclose"><img  @mousedown="$emit('update:isqrcode', false)" src="../../views/home/image/inviteclose.png" alt=""></div>
    </div>
           
  </div>
</template>

<script>
import { getQrcode } from "@/assets/api/index";
export default {
    props:['isqrcode'],
    data(){
        return{
           activeName:'first',
           isinvite:false,
           wxqrcode:'',
           whatqrcode:'',
           isqrspan:0,
           typeList:[
            {
                name:'WeChat',
                id:0
            },
            {
                name:'WhatsApp',
                id:1
            }
           ],
           whatsapplink:''
        }
    },
    mounted(){
        this.getQrcode()
    },
    methods:{
        goInvite(){
            // this.isinvite = true
            // this.$emit('update:isqrcode', false)
            this.$emit('delete')
        },
        async getQrcode() {
            const res = await getQrcode({});
            if (res.code==200) {
                this.wxqrcode = res.data.sys_wx_qrcode
                this.whatqrcode = res.data.sys_whatsapp_qrcode
                this.whatsapplink = res.data.sys_whatsapp_link
            }
        },
        onTypeb(i){
            this.isqrspan = i
        },
        goWeChat(){
            window.location.href='weixin://'
        },
        goWhatApp(){
            window.location.href = this.whatsapplink
        }
    }
}
</script>

<style lang='less' scoped>
.qrcodeclose{
    width: 30px;
    height: 30px;
    margin: 0 auto;
}
.con{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9;
}

.con_box{
    position: relative;
    width: 80%;
    height: 430px;
    margin: 90px auto 24px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;
}
.qrcode_top{
    width: 100%;
    height: 120px;
    background: url('../../views/mobile/image/qrcodea.png') no-repeat;
    background-size: 100%;
    margin-bottom: 24px;
    padding: 38px 20px;
    box-sizing: border-box;
}
.qrtit{
    font-size: 26px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.qrval{
    width: 180px;
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
}
.qrtype{
    width: 100%;
    margin: 14px 0 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.qrtype span{
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #666666;
    margin-right: 30px;
}
.qrtype span:last-child{
    margin-right: 0;
}

.isqrspan{
    position: relative;
    font-weight: 600 !important;
    color: #333333 !important;
}
.isqrspan::after{
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    content: '';
    width: 20px;
    height: 2px;
    background: #2655F0;
}
.qrerwm{
    width: 100%;
    margin-top: 15px;
    
}
.qrerwm img{
    width: 110px;
    height: 100px;
}
.wechat{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wechat img{
    margin-right: 4px;
}
.whats{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.whats img{
    margin-right: 4px;
}
.qrcode_scan{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #666666;
    margin: 15px 0;
}
.qrcode_scan img{
    width: 18px;
    height: 18px;
}
.qrbtn{
    width: 200px;
    height: 40px;
    background: linear-gradient(180deg, #85ADFF 1%, #2655F0 99%);
    box-shadow: 2px 4px 10px 0px rgba(38,85,240,0.3);
    border-radius: 24px 24px 24px 24px;
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
}
.qrcode_bom{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-top: 1px dotted #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #2655F0;
}
</style>

// 设置cookie
export function setCookie(c_name, c_pwd, exdays) {
    const exdate = new Date()// 获取时间
    exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
    // 字符串拼接cookie
    const expires = 'expires=' + exdate.toUTCString()
    window.document.cookie = 'userName=' + c_name + ';path=/;' + expires
    window.document.cookie = 'userPwd=' + c_pwd + ';path=/;' + expires
}
// 读取cookie
export function getCookie(userName) {
    if (document.cookie.length > 0) {
        const arr = document.cookie.split('; ')// 这里显示的格式需要切割一下自己可输出看下
        for (let i = 0; i < arr.length; i++) {
            const arr2 = arr[i].split('=')// 再次切割
            // 判断查找相对应的值
            if (arr2[0] === userName) {
                return arr2[1]
            }
        }
    }
}

// 读取cookie
// getCookie(key) {
//     if (document.cookie.length > 0) {
//         const arr = document.cookie.split('; ')// 这里显示的格式需要切割一下自己可输出看下
//         for (let i = 0; i < arr.length; i++) {
//             const arr2 = arr[i].split('=')// 再次切割
//             // 判断查找相对应的值
//             if (arr2[0] === key) {
//                 return arr2[1]// 保存到保存数据的地方
//             }
//         }
//     }
// },


// 清除cookie
export function clearCookie() {
    // this.setCookie('', '', -1)// 修改2值都为空，天数为负1天就好了
}


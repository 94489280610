<template>
  <!-- 邀请码弹窗 -->
<div>
  <div class="inviteDialog" v-if="isinvite" @mousedown="$emit('update:isinvite', false)">
        <div class="inviteDialog_box" @mousedown.stop="$emit('update:isinvite', true)">
            <div class="invite_title">{{$t('index.INVITE')}}</div>
            <page-code :code="Codelist" @complete='onCode' ref="mycode"/>
            <div class="invite_text" @click="goQrcode">{{$t('index.NOINVITE')}}<img src="../../views/home/image/inviterg.png" alt=""></div>
            <div class="invite_btn">
                <img src="../../views/home/image/sucbtn.png" alt="" v-if="isSuc">
                <img src="../../views/home/image/nobtn.png" alt="" v-else>
            </div>
            <div class="inviteclose" @mousedown="$emit('update:isinvite', false)"><img src="../../views/home/image/inviteclose.png" alt=""></div>
        </div>
        
    </div>
    <!-- <page-qrcode :isqrcode.sync="isqrcode"/> -->
</div>
  

</template>

<script>
import { getCode,getInfo } from "@/assets/api/index";
import { setStore } from "@/assets/utils/utils";
export default {
    props:['isinvite'],
    data(){
        return{
           Codelist: [],
           isSuc:false,
           isqrcode:false
        }
    },
    created(){
        
    },
    mounted(){
    
    },
    methods:{
        onCode(data){
            this.code = data.join('')
            console.log(this.code);
            this.getCode()
            this.isSuc = true
        },
        async getCode() {
            const res = await getCode({
                code:this.code
            });
            if (res.code==200) {
                this.$message({
                    type: "success",
                    message: res.msg,
                });
                this.getInfo()
                
                
            }else{
                this.isSuc = false
                this.$message({
                    type: "error",
                    message: res.msg,
                });
                this.code = ''
                this.$refs.mycode.onclean()
            }
        },
        async getInfo(){
            const res = await getInfo({});
            console.log(res);
          if (res.code==200) {
            setStore('userinfo',res.data)
            this.$store.commit('setUserInfo',res.data)
            setTimeout(() => {
                this.$router.go()
            }, 1000);
          } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            return;
          }
        },
        goQrcode(){
            // this.isqrcode = true
            // this.$emit('update:isinvite', false)
            this.$emit('deleteb')
        }

    }
}
</script>

<style lang='less' scoped>

.inviteDialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9;
}
.inviteDialog_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 500px;
    height: 360px;
    background: url('../../views/home/image/invite.png') no-repeat;
    padding-top: 52px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.invite_title{
    width: 100%;
    font-size: 36px;
    font-family: alibaba-font;
    font-weight: 900;
    color: #333333;
    line-height: 36px;
    text-align: center;
    margin-bottom: 28px;
}
.invite_text{
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #2655F0;
    margin: 24px 0 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.invite_btn{
    width: 100px;
    height: 100px;
}
.inviteclose{
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    margin: 24px auto 0;
    cursor: pointer;
}




</style>
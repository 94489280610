<template>
  <div>
    <div class="left">
        <div class="left_box">
            <div class="left_b" @click="goTopb">
                <div class="yh" v-if="isyh"></div>
                <div class="hy" v-else></div>
            </div>
            <div class="left_c" @click="goTop" v-if="isShow">
                <div class="top"></div>
            </div>
        </div>
    </div>      
  </div>
</template>

<script>
export default {
    data(){
        return{
            isyh:false,
            isShow:true
        }
    },
    created(){

    },
    mounted(){
        window.addEventListener('scroll', this.handleScroll);
        console.log(this.$i18n.locale);
        if(this.$i18n.locale == 'zh-cn'){
            this.isyh=false
            }else{
            this.isyh=true
        }
    },
    methods:{
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            this.isShow = scrollTop > 200 ? true : false 
        },
        goTop(){
            document.querySelector('#onTop').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        },
        goTopb(){
            if(this.$i18n.locale === 'zh-cn'){   // 判断当前语言
            this.$i18n.locale = 'en-us'   // 设置当前语言
            localStorage.setItem('language',this.$i18n.locale)
            this.isyh=true
        } else {
            this.$i18n.locale = 'zh-cn'
            localStorage.setItem('language',this.$i18n.locale)
            this.isyh=false
        }
        },
    }
}
</script>

<style lang='less' scoped>
// 吸顶
.left{
  position: fixed;
  top: 70%;
  right: 10px;
  z-index: 9;
  display: flex;
  align-items: center;
}
.left_b{
  position: relative;
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 10px 10px 20px 0px rgba(192,196,204,0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left_c{
  position: relative;
  width: 48px;
  height: 48px;
  margin-top: 12px;
  background: #FFFFFF;
  box-shadow: 10px 10px 20px 0px rgba(192,196,204,0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top{
  width: 24px;
  height: 24px;
  background: url('../../views/mobile/image/top.png') no-repeat;
  background-size: 24px 24px;
}
.yh{
  width: 24px;
  height: 24px;
  background: url('../../views/mobile/image/chin.png') no-repeat;
  background-size: 24px 24px;
}
.hy{
  width: 24px;
  height: 24px;
  background: url('../../views/mobile/image/eng.png') no-repeat;
  background-size: 24px 24px;
}
</style>
<template>
    <!-- 吸顶容器 -->
    <div class="top">
        <div class="top_box">
            <div class="logo" @click="goback"><img src="../../views/home/image/logo.png" alt=""></div>
            <div class="top_rg">
                <div class="searbl" v-if="isser">
                    <input type="text" v-model="keyword" :placeholder="$t('navbar.SEARCH')" class="searchb" @keyup.enter='goText'>
                    <img src="../../views/home/image/sec.png" alt="" class="searimg" @click="goText">
                </div>
                <div class="search" v-else @click="isser=true"><img src="../../views/home/image/sec.png" alt="">{{$t('navbar.SER')}}</div>
                <div class="login">
                    <div class="user" v-if="usetoken" @mouseover="mycon=true" @mouseleave="mycon=false">
                        <div class="uname">
                            <div class="avatar">
                                <!-- <img src="@/assets/img/avatarb.png" alt=""> -->
                                <img :src="userinfo.avatar" alt="">
                                <img class="sunimg" src="../../views/home/image/sun.png" alt="" v-if="userinfo.is_vip==1">
                            </div>
                            <div class="nickname">{{userinfo.nickname}}</div>
                            <div class="last"><img src="../../views/home/image/last.png" alt=""></div>
                            <div class="maxtext" :class="{maxtextb:$t('navbar.ZH')=='中'}" v-if="userinfo.is_vip==1">{{$t('navbar.MAX')}}</div>
                        </div>
                        <div class="mycon" :class="{myconb:$t('navbar.ZH')=='中'}" v-if="mycon" @mouseover="mycon=true">
                            <li class="lia" @click="goMyDown"><div></div>{{$t('navbar.MYDOWN')}}</li>
                            <li class="lib" @click="goMyEquity"><div></div>{{$t('navbar.MYEQUITY')}}</li>
                            <li class="lic" @click="goMyNumber"><div></div>{{$t('navbar.MYNUMBER')}}</li>
                            <li class="lid" @click="onDeleteOut"><div></div>{{$t('navbar.OUT')}}</li>
                        </div>
                    </div>

                    <div class="nouser" v-else>
                        <div class="avatar"><img src="../../views/home/image/avatar.png" alt=""></div>
                        <div class="gologin">
                            <span @click="islogin=true"><a id="toplogin">{{$t('navbar.LOGIN')}}</a></span>/<span @click="goRegister"><a id="topregister">{{$t('navbar.REGISTER')}}</a></span>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </div>
        <page-login :islogin.sync="islogin"/>
        <page-out :isout.sync="isout" @delete="getOut"/>
    </div>
</template>

<script>
import { getStore,removeStore,setStore } from "@/assets/utils/utils";
import { getLoginOut,getInfo } from "@/assets/api/index";
export default {
  data() {
    return {
        isser:false,
        userinfo:this.$store.state.userInfo||'',
        mycon:false,
        keyword:'',
        usetoken:getStore('token'),
        islogin:false,
        isout:false
    };
  },
  mounted(){
    if(this.usetoken){
        this.getInfo()
    }
  },
  methods: { 
    onDeleteOut(){
        this.isout = true
    },
    async getOut(){
        const res = await getLoginOut({});
            if (res.code==200) {
               removeStore("token");
               removeStore("userinfo");
               this.$store.commit('setUserInfo',null)
               setTimeout(() => {
                this.$router.go(0);
               }, 500);
            }else{
                
        }
    },
    goRegister(){
        if(this.$route.query.ref){
          this.$router.push({
          path:'/register',
          query:{
            ref:this.$route.query.ref
          }
        })
        }else{
          this.$router.push('/register')
        }
    }, 
    goText(){
        sessionStorage.setItem('keyword',this.keyword)
        sessionStorage.removeItem('TextList')
        let id = this.random(0,1000)
        this.$router.push({
            path:'/text',
            query:{
                id:id
            }
        })
        // this.$router.replace({path:'./text'})
    },
    random(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    },
    goback(){
        this.$router.push({path: "/"});
        sessionStorage.removeItem('TextList')
        sessionStorage.removeItem('keyword')
    },
    goMyDown(){
        this.$store.commit('setTypeIndex',0)
        this.$router.push({path: "/user/mydown"});
    },
    goMyEquity(){
        this.$store.commit('setTypeIndex',1)
        this.$router.push({path: "/user/myequity"});
    },
    goMyNumber(){
        this.$store.commit('setTypeIndex',2)
        this.$router.push({path: "/user/mynumber"});
    },
    async getInfo(){
        const res = await getInfo({});
        if (res.code==200) {
            setStore('userinfo',res.data)
        }
    },
  },
};
</script>
<style lang='less' scoped>
input::placeholder{
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #999999;
}
// 吸顶
.top{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
}
.top_box{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    width: 350px;
    height: 40px;
}
.top_rg{
    display: flex;
    align-items: center;
}
.search{
    width: 120px;
    height: 40px;
    border-radius: 20px 20px 20px 20px;
    border: 2px solid #2655F0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 500;
    color: #2655F0;
    box-sizing: border-box;
}
.search img{
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.searchb{
    width: 330px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px 20px 20px 20px;
    border: 2px solid #2655F0;
    padding-left: 24px;
    box-sizing: border-box;
}
.searbl{
    position: relative;
}
.searimg{
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.login{
    height: 100%;
    margin-left: 48px;
}
.nouser{
    display: flex;
    align-items: center;
}
.avatar{
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: 8px;
    border-radius: 50%;
}
.avatar img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.sunimg{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 22px !important;
    height: 22px !important;
}

.gologin{
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #666666;
}
.gologin span{
    cursor: pointer;
}
.gologin span:hover{
    color:#2655F0;
}
.user{
    height: 80px;
    position: relative;
}
.uname{
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nickname{
    
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #333333;
}
.maxtext{
    display: inline-block;
    width: 185px;
    position: absolute;
    bottom: 10px;
    left: 48px;
    height: 18px;
    line-height: 17px;
    text-align: center;
    box-sizing: border-box;
    background-color: #E0E1E3;
    border-radius: 2px;
    font-size: 12px;
    font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
    font-weight: 400;
    color: #444444;
    z-index: 99;
}
.maxtextb{
    width: 80px;
}
.last{
    width: 24px;
    height: 24px;
    // margin-right: 48px;
}
.mycon{
  width: 230px;
  background-color: #FFFFFF;
  position: absolute;
  top: 80px;
  right: 0px;
  border-radius: 4px;
  padding-left: 30px;
  box-sizing: border-box;
  z-index: 99;
  box-shadow: 8px 8px 30px 0px rgba(188,193,204,0.1);
}
.myconb{
    padding-left: 50px;
    box-sizing: border-box;
}
.mycon li{
  display: block;
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: left;
  font-size: 18px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.mycon li:hover{
  color: #2655F0;
}

.lia div{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url('../../views/home/image/down.png') no-repeat;
}
.lia:hover div{
    background: url('../../views/home/image/downb.png') no-repeat;
}
.lib div{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url('../../views/home/image/crown.png') no-repeat;
}
.lib:hover div{
    background: url('../../views/home/image/crownb.png') no-repeat;
}
.lic div{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url('../../views/home/image/uer.png') no-repeat;
}
.lic:hover div{
    background: url('../../views/home/image/uerb.png') no-repeat;
}
.lid div{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url('../../views/home/image/out.png') no-repeat;
}
.lid:hover div{
    background: url('../../views/home/image/outb.png') no-repeat;
}
</style>

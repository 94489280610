import axios from '../utils/axios'
import apiConfig from '../utils/api.config'

axios.defaults.baseURL = apiConfig.baseURL

// 手机号注册
export function getRegister(data) {
    return axios({
        method: 'POST',
        url: "/api/index/phone_register",
        data
    })
}

// 短信验证码
export function getYzm(data) {
    return axios({
        method: 'POST',
        url: "/api/index/slider_verify_img",
        data
    })
}
// 发送手机短信
export function getPhoneCode(data) {
    return axios({
        method: 'POST',
        url: "/api/index/send_sms",
        data
    })
}
// 发送邮箱短信
export function getEmailCode(data) {
    return axios({
        method: 'POST',
        url: "/api/index/send_email",
        data
    })
}
// 手机验证码登录
export function getCodeLogin(data) {
    return axios({
        method: 'POST',
        url: "/api/index/phone_login",
        data
    })
}
// 手机账号登录
export function getLogin(data) {
    return axios({
        method: 'POST',
        url: "/api/index/login",
        data
    })
}
// 隐私政策
export function getPolicy(data) {
    return axios({
        method: 'POST',
        url: "/api/index/policy",
        data
    })
}
// 用户协议
export function getAgree(data) {
    return axios({
        method: 'POST',
        url: "/api/index/agreement",
        data
    })
}

// 退出账号
export function getLoginOut(data) {
    return axios({
        method: 'POST',
        url: "/api/index/login_out",
        data
    })
}

// 邀请码
export function getCode(data) {
    return axios({
        method: 'POST',
        url: "/api/client/use_invitation_code",
        data
    })
}

// 个人中心找回密码
export function getForgot(data) {
    return axios({
        method: 'POST',
        url: "/api/index/forgot_password",
        data
    })
}

// 个人中心修改密码
export function getEdit(data) {
    return axios({
        method: 'POST',
        url: "/api/client/edit_password",
        data
    })
}

// 个人中心个人信息
export function getInfo(data) {
    return axios({
        method: 'POST',
        url: "/api/client/info",
        data
    })
}

// 个人中心修改手机号
export function getEdphone(data) {
    return axios({
        method: 'POST',
        url: "/api/client/edit_phone",
        data
    })
}
// 绑定邮箱
export function getEditEmail(data) {
    return axios({
        method: 'POST',
        url: "/api/client/edit_email",
        data
    })
}
// 修改姓名
export function getEditInfo(data) {
    return axios({
        method: 'POST',
        url: "/api/client/edit_info",
        data
    })
}
// 上传头像
export function getUpload(data) {
    return axios({
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        url: "/api/client/uploadAvatar",
        data
    })
}
// 客服二维码
export function getQrcode(data) {
    return axios({
        method: 'POST',
        url: "/api/index/kefu_qrcode",
        data
    })
}

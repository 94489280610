<template>
  <!-- 邀请码弹窗 -->
<div>
  <div class="holl" v-if="ishellow" @mousedown="$emit('update:ishellow', false)">
        <div class="holl_box" @mousedown.stop="$emit('update:ishellow', true)">
            <div class="title" :class="{titleb:$t('navbar.ZH')=='ZN'}">{{$t('register.HELLO')}}～ <br /> {{$t('register.WELCOME')}} <span> {{$t('register.PANDA')}}</span></div>
            <div class="val_box" v-if="$t('navbar.ZH')=='中'">
                <div class="val_li"><span>1</span>{{$t('register.VALLIA')}}</div>
                <div class="val_li"><span>2</span>{{$t('register.VALLIB')}}</div>
                <div class="val_li"><span>3</span>{{$t('register.VALLIC')}}</div>
                <div class="val_li"><span>4</span>{{$t('register.VALLID')}}</div>
            </div>
            <div class="val_boxb" v-else>
                Log in and add teaching assistant (on WeChat and WhatsApp) to get invitation code for unlimited downloads.
            </div>
            <div class="val_btn" @click="$emit('update:ishellow', false)" :class="{val_btnb:$t('navbar.ZH')=='中'}">{{$t('moblogin.KNOW')}}</div>
            <div class="val_img"><img src="../../views/mobile/image/holl.png" alt=""></div>
        </div>
    </div>
 
</div>
  

</template>

<script>

export default {
    props:['ishellow'],
    data(){
        return{
    
        }
    },
    created(){
        
    },
    mounted(){
    
    },
    methods:{
        

    }
}
</script>

<style lang='less' scoped>

.holl{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9;
    padding: 0 30px;
    box-sizing: border-box;
}
.holl_box{
    position: relative;
    width: 100%;
    height: 340px;
    margin: 200px auto 0;
    box-sizing: border-box;
    padding:40px 24px 0;
    background: #FFFFFF;
    border-radius: 8px;
}
.title{
    font-size: 24px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
    margin-bottom: 15px;
}
.title span{
    color: #2655F0;
}
.titleb{
    width: 220px;
}
.val_li{
    position: relative;
    padding-left: 25px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #333333;
    line-height:16px;
    margin-bottom: 14px;
}
.val_li span{
    position: absolute;
    top: 0px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #2655F0;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #FFFFFF;
}
.val_btn{
    width: 120px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: #2655F0;
    border-radius: 20px 20px 20px 20px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin: 30px auto 0;
}
.val_btnb{
    margin-top: 50px;
}
.val_img{
    position: absolute;
    top: 180px;
    right: 0;
    width: 120px;
    height: 120px;
}
.val_imgb{
    top: 155px;
}
.val_img img{
    width: 100%;
    height: 100%;
}
.val_boxb{
    width: 220px;
    line-height: 20px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #333333;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
Vue.config.productionTip = false

// 引入公共css
import './assets/style/font.css';
import './assets/style/reset.css';
// 引入公共组件
import mComponents from './components'
Vue.use(mComponents)

Vue.use(ElementUI, { locale });
import resetMessage from './assets/utils/reseMessage';
Vue.prototype.$message = resetMessage;

import i18n from './components/language'

import Tab from 'vant/lib/tab';
import 'vant/lib/tab/style';
Vue.use(Tab) // 全局注册Tab

import Tabs from 'vant/lib/tabs';
import 'vant/lib/tabs/style';
Vue.use(Tabs) // 全局注册Tabs

import Loading from 'vant/lib/loading';
import 'vant/lib/loading/style';
Vue.use(Loading) // 全局注册Tabs

// 引入 vue-meta-info
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

// import Base64 from 'js-base64'
// Vue.use(Base64)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
<!-- 退出弹窗 --> 
<div>
    <div class="loading" v-if="isload" @mousedown="$emit('update:isload', false)">
        <div class="load_box">
            <van-loading color="#FFFFFF" type="spinner" class="load"/>
            <div class="load_text"> {{$t('index.LOADA')}}</div>
        </div>
        
    </div>
</div>
  

</template>

<script>

export default {
    props:['isload'],
    data(){
        return{
         
        }
    },
    created(){
        
    },
    mounted(){
    
    },
    methods:{
       

    }
}
</script>

<style lang='less' scoped>
.loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 99;
}
.load_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.load_text{
    color: #FFFFFF;
    margin-top: 10px;
}

</style>
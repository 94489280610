<template>
  <!-- 退出弹窗 -->
<div>
  <div class="inviteDialog" v-if="isout" @mousedown="$emit('update:isout', false)">
        <div class="inviteDialog_box" @mousedown.stop="$emit('update:isout', true)">
            <div class="out_box">
                <div class="out_tit">{{$t('moblogin.OUTTIT')}}</div>
                <div class="out_val">{{$t('moblogin.OUTVAL')}}</div>
            </div>
            <div class="out_bom">
                <div class="out_le" @click="$emit('deleout')">{{$t('moblogin.OUTY')}}</div>
                <div class="out_rg" @click="$emit('update:isout', false)">{{$t('moblogin.OUTN')}}</div>
            </div>
        </div>
    </div>

</div>
  

</template>

<script>

export default {
    props:['isout'],
    data(){
        return{
         
        }
    },
    created(){
        
    },
    mounted(){
    
    },
    methods:{
       

    }
}
</script>

<style lang='less' scoped>

.inviteDialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 99;
}
.inviteDialog_box{
    width: 80%;
    height: 170px;
    margin: 250px auto 0;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 40px;
    box-sizing: border-box;
}
.out_tit{
    width: 100%;
    text-align: center;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #303133;
    margin-bottom: 16px;
}
.out_box{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #606266;
    margin-bottom: 38px;
}
.out_bom{
    width: 100%;
    height: 44px;
    border-top: 1px solid #F0F2F5;
    display: flex;
}
.out_bom div{
    width: 50%;
    height: 100%;
    text-align: center;
    line-height: 46px;
    font-size: 17px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #3D3D3D;
    cursor: pointer;
}
.out_bom div:last-child{
    color: #2655F0;
}
.out_le{
    border-right: 1px solid #F0F2F5;
    box-sizing: border-box;
}
</style>
<template>
  <div>
    <div id="onTop" class="topp"></div>
    <mobile-top />

    <div class="home">
        <div class="banner">
            <div v-if="userInfo">
                <div class="bana" v-if="userInfo.is_vip==1">
                    <div class="ban_box">
                        <div class="bantit">{{$t('register.HELLO')}}～<br /> {{$t('register.WELCOME')}}<span> {{$t('register.PANDA')}}</span></div>
                        <div class="bantext">{{$t('index.BANNERA')}}<br /> {{$t('index.BANNERB')}}</div>
                    </div>
                </div>
                <div class="bana" v-else>
                    <div class="ban_box">
                        <div class="bantit">{{$t('register.HELLO')}}～<br /> {{$t('register.WELCOME')}}<span> {{$t('register.PANDA')}}</span></div>
                        <div class="bantext">{{$t('index.BANNERA')}}</div>
                        <div class="banbtn" @click="isinvite=true" :class="{banbtnb:$t('navbar.ZH')=='ZN'}">{{$t('personal.NOVALB')}} <img src="./image/rgb.png" alt=""></div>
                    </div>
                    
                </div>
            </div>
            <div class="bana" v-else>
               <div class="ban_box">
                    <div class="bantit">{{$t('register.HELLO')}}～<br /> {{$t('register.WELCOME')}}<span> {{$t('register.PANDA')}}</span></div>
                    <div class="bantext">{{$t('index.BANNERC')}}</div>
                    <div class="banbtn" @click="goRegister">{{$t('index.BANNERG')}} <img src="./image/rgb.png" alt=""></div>
                </div>
            </div>
        </div>
        <div class="type">
            <span v-for="(item,index) in typeList" :key="index" :class="{isspan:isspan==index}" @click="onType(index)">{{$t('navbar.ZH')=='中'?item.name:item.name_en}}</span>
        </div>
        <div class="typeb" v-if="istype">
            <span v-for="(item,index) in typeList" :key="index" :class="{isspan:isspan==index}" @click="onType(index)">{{$t('navbar.ZH')=='中'?item.name:item.name_en}}</span>
        </div>
        <div class="search">
            <div class="search_box" @click="goSearch">
                <div class="sea_text">{{$t('moblogin.TEXTSER')}}</div>
                <div class="sea_img"><img src="./image/search.png" alt=""></div>
            </div>
        </div>
        <div class="text" v-if="isspan==0">
            <div class="text_box" v-for="(item,index) in textList" :key="index">
                <div class="text_top">
                    <!-- <div class="text_tit" v-if="$t('navbar.ZH')=='中'">{{item.subject_info.name}}</div>
                    <div class="text_tit" v-else>{{item.subject_info.name_en}}</div> -->
                    <div class="text_tit" v-if="$t('navbar.ZH')=='中'&& item.subject_info">{{item.subject_info.name}}</div>
                    <div class="text_tit" v-if="$t('navbar.ZH')=='ZN' && item.subject_info">{{item.subject_info.name_en}}</div>
                    <div class="text_btn" @click="fileDownload(item.id,item.title)"><div></div><a>{{$t('index.DOWNBTN')}}</a></div>
                </div>
                <div class="text_con" @click="onDetails(item.id)">{{item.title}}</div>
                <div class="text_bom">
                    <span>{{$t('index.uplodeA')}}：{{item.create_time}}</span>
                    <span><img src="../home/image/can.png" alt="">{{item.preview_num}}</span>
                    <span><img src="../home/image/downc.png" alt="">{{item.download_num}}</span>
                </div>
            </div>
            <div class="morebtn" @click="onMore" v-if="ismorebtn">{{$t('moblogin.MORE')}}</div>
        </div>
        <div class="school" v-else-if="isspan==1">
            <div class="school_box" v-for="(item,index) in hotList" :key="index" @click="goSchool(item.id,item.name,item.name_en)">
                <div class="sch_le">
                    <div class="sch_img"><img :src="item.cover_url" alt=""></div>
                    <div class="sch_con">
                        <div class="sch_title" v-if="$t('navbar.ZH')=='中'">{{item.name}}</div>
                        <div class="sch_title" v-else>{{item.name_en}}</div>
                        <div class="sch_val" v-if="$t('navbar.ZH')=='中'">已上传{{item.number}}套试题</div>
                        <div class="sch_val" v-else>{{item.number}} sets of test papers</div>
                    </div>
                </div>
                <div class="sch_btn"><a>{{$t('index.SCHOOLBTN')}}</a></div>
            </div>
        </div>
        <div class="news" v-else>
            <div class="news_box" v-for="(item,index) in newsList" :key="index" @click="onNewdetails(item.id)">
                <div class="news_left">
                    <div class="news_title" v-if="$t('navbar.ZH')=='中'">{{item.title}}</div>
                    <div class="news_title" v-else>{{item.title_en}}</div>
                    <div class="news_bom">
                         <span>{{$t('index.uplodeA')}}：{{item.publish_time?item.publish_time.slice(0,10):item.create_time.slice(0,10)}}</span>
                         <span><img src="../home/image/can.png" alt="">{{item.viewnum}}</span>
                    </div>
                </div>
                <div class="news_rg">
                    <img src="./image/newsmbtn.png" alt="">
                </div>
            </div>
            <div class="morebtn" @click="onMoreb" v-if="ismorebtnb">{{$t('moblogin.MORE')}}</div>
        </div>
    </div>

    <mobile-left />
    <mobile-bot />
    <mobile-qrcode :isqrcode.sync="isqrcode" @delete="onDeleteItem"/>
    <mobile-invite :isinvite.sync="isinvite" @deleteb="onDeleteItemb" @invitesuc='onInviSuc'/>
    <mobile-suc :isinvitesuc.sync="isinvitesuc"/>
    <mobile-load :isload.sync="isload"/>
    
  </div>
</template>

<script>
import { getNewsList } from "@/assets/api/news";
import { getText,getHotschool,getTextDown } from "@/assets/api/text";
import { getStore } from "../../assets/utils/utils";
export default {
    data(){
        return{
            typeList:[
                {
                    name:'热门下载',
                    name_en:'Top Downloads'
                },
                {
                    name:'热门学校',
                    name_en:'Hot schools'
                },
                {
                    name:'最新资讯',
                    name_en:'Latest news'
                }
            ],
            isspan:0,
            textList:[],
            page:1,
            pageSize:10,
            hotList:[],
            istype:false,
            ismore:false,
            ismorebtn:false,
            isqrcode:false,
            isinvite:false,
            isinvitesuc:false,
            userInfo: JSON.parse(getStore('userinfo')),
            isload:false,
            newsList:[],
            pageb:1,
            pageSizeb:10,
            ismoreb:false,
            ismorebtnb:false
        }
    },
    created(){
       this.getText()
    },
    mounted(){
        window.addEventListener('scroll', this.handleScrollb);
        //监听关闭时间
        window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    },
    methods:{
        async getNewsList() {
            const res = await getNewsList({
                page:this.pageb,
                pageSize:this.pageSizeb
            });
            if (res.code==200) {          
                if(this.ismoreb){
                    this.newsList=this.newsList.concat(res.data.list)
                    if(this.newsList.length==res.data.total){
                        this.ismoreb=false
                        this.ismorebtnb = false
                    }
                }else{
                    this.newsList = res.data.list
                    if(this.newsList.length<res.data.total){
                        this.ismorebtnb = true
                    }
                }
                console.log(this.newsList);
            }
        },
        beforeunloadHandler(e) {
        //说明页面全部关闭，这时候可以传数据给后台
            let leave_time = Math.round(Date.now() / 1000)
            let entry_time = Math.round(localStorage.getItem('entry_timea') / 1000)
    
            if (leave_time - entry_time > 3) { //30毫米内算刷新处理
                localStorage.setItem('issx', 1)
                console.log('页面未刷新');
                localStorage.removeItem('entry_timea')
            } else {
                console.log('页面刷新')
                localStorage.setItem('issx', 2)
            }
        },
        goRegister(){
            if(this.$route.query.ref){
            this.$router.push({
            path:'/mobile_register',
            query:{
                ref:this.$route.query.ref
            }
            })
            }else{
            this.$router.push('/mobile_register')
            }
        },
        onDeleteItem(){
            this.isinvite = true
            this.isqrcode = false
        },
        onDeleteItemb(){
            this.isqrcode = true
            this.isinvite = false
        },
        onInviSuc(){
            this.isinvitesuc = true
            this.isinvite = false
        },
        async getText() {
            const res = await getText({
                page:this.page,
                pageSize:this.pageSize
            });
            if (res.code==200) {
                if(this.ismore){
                    this.textList=this.textList.concat(res.data.list)
                    if(this.textList.length==res.data.total){
                        this.ismore=false
                        this.ismorebtn = false
                    }
                }else{
                    this.textList = res.data.list
                    if(this.textList.length<res.data.total){
                        this.ismorebtn = true
                    }
                }
                
                console.log(this.textList);
            }
        },
        async getHotschool() {
            const res = await getHotschool({
                page:1,
                pageSize:5
            });
            if (res.code==200) {
                this.hotList = res.data
                console.log(this.hotList);
            }
        },
        onType(i){
            this.isspan = i
            if(this.isspan==0){
                this.page=1
                this.getText()
            }else if(this.isspan==1){
                this.getHotschool()
            }else{
                this.pageb=1
                this.getNewsList()
            }
            document.querySelector('#onTop').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        },
        goSearch(){
            this.$router.push('/mobile_search')
        },
        handleScrollb() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            this.istype = scrollTop > 200 ? true : false 
        },
        onMore(){
            this.page++
            this.ismore=true
            this.getText()
        },
        onMoreb(){
            this.pageb++
            this.ismoreb=true
            this.getNewsList()
        },
        onDetails(id){
            this.$router.push({
                path: `/mobile_textdetails/${id}`
            })
        },
        async fileDownload(id,name){

        if(!this.userInfo){
          this.$router.push('/mobile_login')
          return
        }
        if(this.userInfo.is_vip == 1){
            this.isload = true
            try{
                const res = await getTextDown({
                id:id
            });
            if(res.type === 'application/json'){   
                const fileReader = new FileReader()
                fileReader.onloadend = () => {
                    const jsonData = JSON.parse(fileReader.result)
                    // 后台信息
                    this.isload=false
                    if(jsonData.code==102){
                    localStorage.removeItem("token");
                    localStorage.removeItem("userinfo");
                    this.$message.error(jsonData.msg);
                    this.$router.replace({
                        path: '/mobile_login'
                    })
                    return
                    }
                    if(jsonData.code){
                    this.$message.error(jsonData.msg);
                    return
                    }
                }
                fileReader.readAsText(res)
                }else{
                    const a = document.createElement('a');
                    a.download = name+'.pdf';
                    a.style.display = 'none';
                    // const blob = new Blob([res], { type: 'application/x-msdownload' });
                    const blob = new Blob([res], { type: ' applicatoin/octet-stream' });
                    a.href = URL.createObjectURL(blob);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    this.isload = false
                }
            }catch(error){
                 this.isload=false
                 this.$message({
                    message: this.$t('index.LOADC'),
                });
            }
          
            
        }else{
          this.isqrcode=true
          return
        }
        },
        goSchool(id,name,name_en){
            let TextList = [ [], [],[],[],[id],]
            if(this.$i18n.locale == 'zh-cn'){
                let NameList = [ [], [],[],[],[name],]
                sessionStorage.setItem('NameList',JSON.stringify(NameList))
            }else{
                let NameList = [ [], [],[],[],[name_en],]
                sessionStorage.setItem('NameList',JSON.stringify(NameList))
            }
            
            sessionStorage.setItem('TextList',JSON.stringify(TextList))
            this.$router.push('/mobile_text')
        },
        onNewdetails(id){
            this.$router.push({
                path: `/mobile_newsDetail/${id}`
            })
        }
    },
    destroyed() {
      window.removeEventListener("beforeunload", (e) =>
        this.beforeunloadHandler(e)
      );
    },
}
</script>

<style lang='less' scoped>
.news{
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
}
.news_box{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 8px 8px 30px 0px rgba(188, 193, 204, 0.1);
    border-radius: 8px 8px 8px 8px;
    padding: 14px 12px 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}
.news_left{
    width: 85%;
}
.news_title{
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;  // 控制多行的行数
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
}

.news_bom{
    display: flex;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #999999;
}
.news_bom span{
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.news_bom span:last-child{
    margin-right: 0;
}
.news_rg img{
    width: 30px;
    height: 30px;
    margin-left: 13px;
}

.morebtn{
    width: 120px;
    height: 32px;
    background: linear-gradient(180deg, #85ADFF 1%, #2655F0 99%);
    box-shadow: 2px 4px 10px 0px rgba(38,85,240,0.3);
    border-radius: 16px 16px 16px 16px;
    margin: 0 auto 32px;
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 32px;
}
.topp{
  position: absolute;
  top: 0;
  width: 0;
  left: 0;
}
.home{
    margin-top: 44px;
}
.banner{
    width: 100%;
    background: #FFFFFF;
}
.bana{
    background: url('./image/bana.png') no-repeat;
    background-size: 100% 100%;
    padding: 40px 0px;
    box-sizing: border-box;
}
.ban_box{
    padding: 0 30px;
    box-sizing: border-box;
}
.bantit{
    font-size: 22px;
    font-family: alibaba-font;
    font-weight: 900;
    color: #333333;
}
.bantit span{
    color: #2655F0;
}
.bantext{
    font-size: 12px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #666666;
    margin: 12px 0 16px;
    line-height: 14px;
}
.banbtn{
    width: 120px;
    height: 32px;
    background: linear-gradient(180deg, #85ADFF 1%, #2655F0 99%);
    box-shadow: 2px 4px 10px 0px rgba(38,85,240,0.3);
    border-radius: 16px 16px 16px 16px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banbtn img{
    width: 12px;
    height: 12px;
}
.banbtnb{
    width: 170px;
    position: relative;
    font-size: 12px;
    line-height: 12px;
    padding: 0 5px;
}
.type{
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 12px;
}
.type span{
    display: inline-block;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #666666;
}
.type span:nth-child(2){
    margin: 0 14px;
}
.typeb{
    position: fixed;
    top: 44px;
    left: 0;
    background: #FFFFFF;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.typeb span{
    display: inline-block;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #666666;
}
.typeb span:nth-child(2){
    margin: 0 14px;
}
.isspan{
    position: relative;
    font-weight: 600 !important;
    color: #333333 !important;
}
.isspan::after{
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    content: '';
    width: 16px;
    height: 2px;
    background: #2655F0;
}
.search{
    width: 100%;
    margin-bottom: 12px;
    padding: 0 15px;
    box-sizing: border-box;
}
.search_box{
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    box-shadow: 4px 4px 10px 0px rgba(188,193,204,0.2);
    border-radius: 8px 8px 8px 8px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sea_text{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #999999;
}
.sea_img img{
    width: 20px;
    height: 20px;
}
.text{
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
}
.text_box{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 8px 8px 30px 0px rgba(188,193,204,0.1);
    border-radius: 8px 8px 8px 8px;
    padding: 14px 12px 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
}
.text_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.text_tit{
    display: inline-block;
    width: auto;
    height: 24px;
    background: #2655F0;
    border-radius: 2px 2px 2px 2px;
    padding: 0 7px;
    box-sizing: border-box;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
}
.text_btn{
    display: inline-block;
    width: auto;
    height: 24px;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    border: 1px solid #2655F0;
    padding: 0 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #2655F0;
}
.text_btn a{
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #2655F0;
}
.text_btn div{
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background: url('./image/down.png') no-repeat;
    background-size: 16px 16px;
}
.text_con{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 18px;
    margin: 10px 0 12px;
}
.text_bom{
    display: flex;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #999999;
}
.text_bom span{
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.text_bom span:last-child{
    margin-right: 0;
}

.school{
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
}
.school_box{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 4px 4px 10px 0px rgba(188,193,204,0.2);
    border-radius: 8px 8px 8px 8px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.sch_le{
    display: flex;
    align-items: center;
}
.sch_img{
    width: 48px;
    height: 48px;
    margin-right: 8px;
    border-radius: 8px;
    overflow: hidden;
}
.sch_img img{
    width: 100%;
    height: 100%;
}
.sch_con{
    width: 193px;
}
.sch_title{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 16px;
    margin-bottom: 4px;
}
.sch_val{
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #999999;
}
.sch_btn{
    display: inline-block;
    width: auto;
    height: 24px;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    border: 1px solid #2655F0;
    font-size: 12px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #2655F0;
    text-align: center;
    line-height: 24px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sch_btn a{
    font-size: 12px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #2655F0;
}
</style>
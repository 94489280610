<template>
  <div>
    <div id="onTop" class="topp"></div>
    <page-top />

    <div class="home">

      <div class="banner">
        <div v-if="userInfo">
          <div class="banc" v-if="userInfo.is_vip==1">
            <div class="ban_box">
              <div class="bantit">{{$t('register.HELLO')}}～<br /> {{$t('register.WELCOME')}}<span> {{$t('register.PANDA')}}</span></div>
              <div class="bantext">{{$t('index.BANNERA')}}</div>
              <div class="bantextb">{{$t('index.BANNERB')}}</div>
            </div>
          </div>
          <div class="banb" v-else>
            <div class="ban_box">
              <div class="bantit">{{$t('register.HELLO')}}～<br /> {{$t('register.WELCOME')}}<span> {{$t('register.PANDA')}}</span></div>
              <div class="bantext">{{$t('index.BANNERA')}}</div>
              <div class="banbtnb" :class="{banbtnbs:$t('navbar.ZH')=='中'}" @click="isqrcode=true"> 
                <div class="banval">{{$t('index.BANNERD')}}</div>
                {{$t('index.BANNERF')}}
              </div>
            </div>
          </div>
        </div>
        
        <div class="bana" v-else>
          <div class="ban_box">
              <div class="bantit">{{$t('register.HELLO')}}～<br /> {{$t('register.WELCOME')}}<span>{{$t('register.PANDA')}}</span></div>
              <div class="bantext">{{$t('index.BANNERC')}}</div>
              <div class="banbtn" @click="goRegister">{{$t('index.BANNERG')}} <img src="./image/banrg.png" alt=""></div>
          </div>
        </div>
      </div>

      <div class="conbox">
        <div class="conbox_le">
          <div class="hot_tit"><span></span>{{$t('index.TITA')}}</div>
          <div class="text_con" v-if="textList.length">
            <div class="text_box" v-for="(item,index) in textList" :key="index">
              <div class="text_le" @click="onDetails(item.id)">
                <div class="text_tit" v-if="$t('navbar.ZH')=='中'&& item.subject_info">{{item.subject_info.name}}</div>
                <div class="text_tit" v-if="$t('navbar.ZH')=='ZN' && item.subject_info">{{item.subject_info.name_en}}</div>
                <div class="text_title">{{item.title}}</div>
                <div class="text_bom">
                  <span>{{$t('index.uplodeA')}}：{{item.create_time}}</span>
                  <span><img src="./image/can.png" alt="">{{item.preview_num}} {{$t('index.uplodeB')}}</span>
                  <span><img src="./image/downc.png" alt="">{{item.download_num}} {{$t('index.uplodeC')}}</span>
                </div>
              </div>
              <div class="text_btn" @click="fileDownload(item.id,item.title)"><div :class="{text_btnb:$t('navbar.ZH')=='中'}"></div> <a>{{$t('index.DOWNBTN')}}</a> </div>
              
            </div>
          </div>
        </div>
        <div class="conbox_rg">
          <!-- 发现试卷 -->
          <div class="cantext">
            <div class="hot_tit"><span></span>{{$t('index.TITB')}}</div>
            <!-- 日期 -->
            <el-select v-model="valuea" multiple  :placeholder="$t('index.SELECTA')" class="elselect" @change="onChangea">
              <el-option
                v-for="(item,index) in cateLista"
                :key="index"
                :label="$t('navbar.ZH')=='中'? item.name : item.name_en"
                :value="item.id">
              </el-option>
            </el-select>
            <!-- 年级 -->
            <el-select v-model="valueb" multiple  :placeholder="$t('index.SELECTB')" class="elselect" @change="onChangeb">
              <el-option
                v-for="(item,index) in cateListb"
                :key="index"
                :label="$t('navbar.ZH')=='中'? item.name : item.name_en"
                :value="item.id">
              </el-option>
            </el-select>
            <!-- 科目 -->
            <el-select v-model="valuec" multiple  :placeholder="$t('index.SELECTC')" class="elselect" @change="onChangec">
              <el-option
                v-for="(item,index) in cateListc"
                :key="index"
                :label="$t('navbar.ZH')=='中'? item.name : item.name_en"
                :value="item.id">
              </el-option>
            </el-select>
            <!-- 评级 -->
            <el-select v-model="valued" multiple  :placeholder="$t('index.SELECTD')" class="elselect" @change="onChanged">
              <el-option
                v-for="(item,index) in cateListd"
                :key="index"
                :label="$t('navbar.ZH')=='中'? item.name : item.name_en"
                :value="item.id">
              </el-option>
            </el-select>
            <!-- 学校 -->
            <el-select v-model="valuee" multiple filterable :placeholder="$t('index.SELECTE')" class="elselect" @change="onChangee">
              <el-option
                v-for="(item,index) in cateListe"
                :key="index"
                :label="$t('navbar.ZH')=='中'? item.name : item.name_en"
                :value="item.id">
              </el-option>
            </el-select>
            <div class="canbtn" @click="onText"><a>{{$t('index.TEXTBTN')}}</a></div>
          </div>
          <!-- 热门学校 -->
          <div class="hotscool">
            <div class="hot_tit"><span></span>{{$t('index.TITC')}}</div>
            <div class="hot_box" v-for="(item,index) in hotList" :key="index">
              <div v-if="hotList.length" class="hot_nr">
                  <div class="hot_img"><img :src="item.cover_url"></div>
                  <div class="hot_con">
                    <div class="hot_title" v-if="$t('navbar.ZH')=='中'">{{item.name}}</div>
                    <div class="hot_title" v-else>{{item.name_en}}</div>
                    <div class="hot_val" v-if="$t('navbar.ZH')=='中'">已上传{{item.number}}套试题</div>
                    <div class="hot_val" v-else>{{item.number}} sets of test papers</div>
                  </div>
                  <div class="hot_btn" @click="goSchool(item.id)"><a>{{$t('index.SCHOOLBTN')}}</a></div>
              </div>
             
            </div>
          </div>
          <!-- 新闻资讯 -->
          <div class="newsinfor" @click="onNews">
            <div class="news_img"><img src="./image/newsban.png" alt=""></div>
            <div class="news_tit" v-if="$t('navbar.ZH')=='中'">可能有试卷不在熊猫搜卷，<br /> 但熊猫搜卷一定有你需要的试卷。</div>
            <div class="news_titb" v-else>PandaTestPaper may not have all the test papers, but it must have the test papers you need.</div>
            <div class="news_val" v-if="$t('navbar.ZH')=='中'">关注熊猫搜卷，<br /> 第一时间获取新加坡考试资讯。</div>
            <div class="news_valb" v-else>Follow PandaTestPaper to get the Singapore exam info as soon as possible.</div>
            <div class="news_btn"></div>
          </div>
        </div>
      </div>

    </div>

    <page-bot />
    <page-left />
    <page-login :islogin.sync="islogin" />
    <page-qrcode :isqrcode.sync="isqrcode" @delete="onDeleteItem"/>
    <page-invite :isinvite.sync="isinvite" @deleteb="onDeleteItemb"/>
    <page-load :isload.sync="isload" />
  </div>
</template>

<script>
import { getText,getCate,getHotschool,getTextDown } from "@/assets/api/text";
import { getStore,removeStore } from "../../assets/utils/utils";
export default {
  
    data(){
        return{
          textList:[],
          cateLista:[],  //日期
          cateListb:[],  //年级
          cateListc:[],  //科目
          cateListd:[],  //阶段
          cateListe:[],  //学校
          valuea:[],
          valueb:[],
          valuec:[],
          valued:[],
          valuee:[],
          hotList:[],
          userInfo: JSON.parse(getStore('userinfo')),
          isqrcode:false,
          isinvite:false,
          islogin:false,
          isload:false
        }
    },
    created(){
        this.getText()
        this.getCate()
        this.getHotschool()
        console.log(this.userInfo);
    },
    methods:{
      beforeunloadHandler(e) {
      //说明页面全部关闭，这时候可以传数据给后台
        let leave_time = Math.round(Date.now() / 1000)
        let entry_time = Math.round(localStorage.getItem('entry_timea') / 1000)
 
        if (leave_time - entry_time > 3) { //30毫米内算刷新处理
            localStorage.setItem('issx', 1)
            console.log('页面未刷新');
            localStorage.removeItem('entry_timea')
        } else {
            console.log('页面刷新')
            localStorage.setItem('issx', 2)
        }
      },
      goRegister(){
        if(this.$route.query.ref){
          this.$router.push({
          path:'/register',
          query:{
            ref:this.$route.query.ref
          }
        })
        }else{
          this.$router.push('/register')
        }
      },
      onDeleteItem(){
        this.isinvite = true
        this.isqrcode = false
      },
      onDeleteItemb(){
        this.isqrcode = true
        this.isinvite = false
      },
      async getText() {
        const res = await getText({
          page:1,
          pageSize:9
        });
          if (res.code==200) {
              this.textList = res.data.list
              // console.log(this.textList);
          }else{
                
          }
      },
      async getCate() {
        const res = await getCate({});
          if (res.code==200) {
            // console.log(res.data);
            this.cateLista = res.data.year
            this.cateListb = res.data.class
            this.cateListc = res.data.subject
            this.cateListd = res.data.stage
            this.cateListe = res.data.school
          }else{
                
          }
      },
      async getHotschool() {
        const res = await getHotschool({});
          if (res.code==200) {
            // console.log(res.data);
            this.hotList = res.data
          }
      },
      onChangea(val){
        // console.log(val);
      },
      onChangeb(val){
        // console.log(val);
      },
      onChangec(val){
        // console.log(val);
      },
      onChanged(val){
        // console.log(val);
      },
      onChangee(val){
        // console.log(val);
      },
      onText(){
        let TextList = [ this.valuea, this.valueb,this.valuec,this.valued,this.valuee,]
        sessionStorage.setItem('TextList',JSON.stringify(TextList))
        sessionStorage.removeItem('keyword')
        this.$router.push('/text')
      },
      onDetails(id){
        this.$router.push({
          path: `/textdetails/${id}`
        })
      },
      async fileDownload(id,name){
        if(!this.userInfo){
          this.islogin = true
          return
        }
        if(this.userInfo.is_vip == 1){
          this.isload=true
          try{
          const res = await getTextDown({
              id:id
          });
         if(res.type === 'application/json'){   
          const fileReader = new FileReader()
          fileReader.onloadend = () => {
            const jsonData = JSON.parse(fileReader.result)
            // 后台信息
            this.isload=false
             if(jsonData.code==102){
              removeStore("token");
              removeStore("userinfo");
              this.$message.error(jsonData.msg);
              this.$router.replace({
                  path: '/login'
              })
              return
            }
            if(jsonData.code){
              this.$message.error(jsonData.msg);
              return
            }
          }
          fileReader.readAsText(res)
        }else{
          const a = document.createElement('a');
            a.download = name+'.pdf';
            a.style.display = 'none';
            // const blob = new Blob([res], { type: 'application/x-msdownload' });
            const blob = new Blob([res], { type: ' applicatoin/octet-stream' });
            a.href = URL.createObjectURL(blob);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.isload=false
         }
          }catch(error){
                 this.isload=false
                 this.$message({
                    message: this.$t('index.LOADC'),
                });
            }
        }else{
          this.isqrcode=true
          return
        }

      },
      goSchool(id){
        this.valuee.push(id)
        this.valuea=[] 
        this.valueb=[]
        this.valuec=[]
        this.valued=[]
        let TextList = [ this.valuea, this.valueb,this.valuec,this.valued,this.valuee,]
        sessionStorage.setItem('TextList',JSON.stringify(TextList))
        sessionStorage.removeItem('keyword')
        this.$router.push('/text')
      },
      onNews(){
        this.$router.push('/news')
      }
    },
    mounted() {
      //监听关闭时间
      window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    },
    destroyed() {
      window.removeEventListener("beforeunload", (e) =>
        this.beforeunloadHandler(e)
      );
    },
}
</script>

<style lang='less' scoped>
.topp{
  position: absolute;
  top: 0;
}
.elselect{
  width: 100%;
  margin-bottom: 20px;
}
.home{
  width: 100%;
  margin-top: 80px;
}
.banner{
  width: 100%;
}
.bana{
  width: 100%;
  height: 400px;
  background: url('./image/bana.png') no-repeat 100%;
}
.banb{
  width: 100%;
  height: 400px;
  background: url('./image/banb.png') no-repeat 100%;
}
.banc{
  width: 100%;
  height: 400px;
  background: url('./image/banc.png') no-repeat 100%;
}
.ban_box{
  width: 1200px;
  margin: 0 auto;
  padding-top: 64px;
  box-sizing: border-box;
}
.bantit{
  font-size: 44px;
  font-family: alibaba-font;
  font-weight: 900;
  color: #333333;
  line-height: 54px;
  margin-bottom: 20px;
}
.bantit span{
  color: #2655F0;
}
.bantext{
  font-size: 28px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #666666;
  // line-height: 40px;
  width: 536px;
}
.bantextb{
  font-size: 28px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #666666;
  // line-height: 40px;
  width: 536px;
  margin-top: 20px;
}

.banbtn{
  width: 220px;
  height: 60px;
  background: linear-gradient(180deg, #85ADFF 1%, #2655F0 99%);
  box-shadow: 4px 10px 20px 0px rgba(38,85,240,0.4);
  border-radius: 30px 30px 30px 30px;
  text-align: center;
  line-height: 60px;
  font-size: 20px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #FFFFFF;
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.banbtnb{
  width: 320px;
  height: 70px;
  background: linear-gradient(180deg, #85ADFF 1%, #2655F0 99%);
  box-shadow: 4px 10px 20px 0px rgba(38,85,240,0.4);
  border-radius: 35px;
  text-align: center;
  font-size: 14px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.banbtnbs{
  width: 240px;
}
.banval{
  font-size: 22px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 5px;
}


.conbox{
  margin: 30px auto 60px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.conbox_le{
  width: 870px;
  background-color: #FFFFFF;
  padding: 30px 24px 0;
  box-sizing: border-box;
}

.hot_tit{
  font-size: 20px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #333333;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.hot_tit span{
  display: inline-block;
  width: 4px;
  height: 18px;
  background-color: #2655F0;
  margin-right: 8px;
}
.text_box{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text_tit{
  display: inline-block;
  width: auto;
  height: 28px;
  border-radius: 8px;
  background-color: #2655F0;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  line-height: 28px;
  margin-bottom: 20px;
}
.text_le{
  width: 680px;
}
.text_title{
  font-size: 18px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #333333;
  line-height: 18px;
  margin-bottom: 17px;
}
.text_bom{
  font-size: 14px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  display: flex;
  align-items: center;
}
.text_bom span{
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.text_bom img{
  margin-right: 3px;
}
.text_btn{
  width: 120px;
  height: 40px;
  border-radius: 20px 20px 20px 20px;
  border: 2px solid #2655F0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #2655F0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}
.text_btn a{
  font-size: 16px;
  font-family: alibaba-font;
  font-weight: 600;
  color: #2655F0;
}
.text_btn div{
  width: 24px;
  height: 24px;
  background: url('./image/downd.png') no-repeat;
}
.text_btnb{
  margin-right: 8px;
}
.text_btn:hover{
  border: none;
  background: linear-gradient(180deg, #85ADFF 0%, #2655F0 100%);
  color: #FFFFFF;
}
.text_btn:hover a{
  color: #FFFFFF;
}
.text_btn:hover div{
  background: url('./image/downf.png') no-repeat;
}
.conbox_rg{
  width: 300px;
}
.cantext{
  width: 100%;
  background-color: #FFFFFF;
  padding: 30px 20px 35px;
  box-sizing: border-box;
}
.canbtn{
  width: 260px;
  height: 48px;
  background: linear-gradient(180deg, #85ADFF 1%, #2655F0 99%);
  box-shadow: 2px 4px 10px 0px rgba(38,85,240,0.4);
  border-radius: 24px 24px 24px 24px;
  font-size: 18px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.canbtn a{
  font-size: 18px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #FFFFFF;
}
.canbtn:hover{
  box-shadow: 4px 8px 16px 0px rgba(38,85,240,0.5);
}
.hotscool{
  width: 100%;
  background-color: #FFFFFF;
  margin-top: 30px;
  padding: 30px 20px 10px;
  box-sizing: border-box;
}
.hot_box{
  margin-bottom: 20px;
}
.hot_nr{
  display: flex;
  align-items: center;
}
.hot_img{
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.hot_img img{
  width: 100%;
  height: 100%;
}
.hot_con{
  width: 144px;
  margin-left: 8px;
  margin-right: 12px;
}
.hot_title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.hot_val{
  font-size: 12px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #999999;
}
.hot_btn{
  width: 48px;
  height: 24px;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #2655F0;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #2655F0;
  cursor: pointer;
}
.hot_btn a{
  font-size: 12px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #2655F0;
}
.hot_btn:hover{
  border: 1px solid #85ADFF;
  color: #85ADFF;
}
.hot_btn:hover a{
  color: #85ADFF;
}
.newsinfor{
  position: relative;
  width: 100%;
  height: 350px;
  background-color: #FFFFFF;
  margin-top: 30px;
  padding: 20px 24px;
  box-sizing: border-box;
}
.newsinfor:hover .news_btn{
  background: url('./image/newsbtnb.png') no-repeat;
}
.news_img{
  width: 100%;
  height: 132px;
  margin-bottom: 12px;
}
.news_img img{
  width: 100%;
  height: 100%;
}
.news_tit{
  font-size: 17px;
  font-family: alibaba-font;
  font-weight: 800;
  color: #333333;
  line-height: 23px;
  margin-bottom: 10px;
}
.news_titb{
  font-size: 16px;
  font-family: alibaba-font;
  font-weight: 800;
  color: #3D3D3D;
  line-height: 21px;
  margin-bottom: 10px;
}
.news_val{
  font-size: 14px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.news_valb{
  font-size: 12px;
  font-family: alibaba-font;
  font-weight: 400;
  color: #3D3D3D;
  line-height: 21px;
}

.news_btn{
  position: absolute;
  bottom: 20px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url('./image/newsbtna.png') no-repeat;
}

</style>
import Vue from 'vue'
import Vuex from 'vuex'
// import { getUserInfo } from "@/assets/api/index";
import { setStore, getStore } from "../assets/utils/utils";
Vue.use(Vuex)
const TOKEN = 'vue_admin_template_token'
const USER_INFO = 'userInfo'
export default new Vuex.Store({
  state() {
    return {
      token: getStore('token') || '',
      userInfo: JSON.parse(getStore('userinfo')) || {},
      typeIndex: 0
    }
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data
    },
    setTypeIndex(state, data) {
      state.typeIndex = data
    },
  },
  actions: {
    // async getUserInfo(store) {
    //   const res = await getUserInfo({})
    //   store.commit('setUserInfo', res.data)
    // }
  },
  modules: {
  }
})

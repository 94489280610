<template>
  <div>
    <div class="con" v-if="isqrcode" @mousedown="$emit('update:isqrcode', false)">
        <div class="con_box" @mousedown.stop="$emit('update:isqrcode', true)">   
                <div class="qrcode_top">
                    <div class="qrtit">{{$t('index.CONTACT')}}</div>
                    <div class="qrvalb" v-if="isdetails">{{$t('index.GETINVITB')}}</div>
                    <div class="qrval" v-else>{{$t('index.GETINVITA')}}</div>
                </div>
                <div class="qrcode_box">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="WeChat" name="first">
                            <div class="qrcodeimg">
                                <!-- <qrcode-vue  :value="wxqrcode" :size="152" level="H" />  -->
                                <img :src="wxqrcode" alt="">
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="WhatsApp" name="second">
                            <div class="qrcodeimg">
                                <img :src="whatqrcode" alt="">
                               <!-- <qrcode-vue  :value="whatqrcode" :size="152" level="H" />  -->
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="qrcode_scan" v-if="activeName=='first'"><img src="../../views/home/image/scan.png" alt="">{{$t('index.SCAN')}}</div>
                <div class="qrcode_scan" v-else><img src="../../views/home/image/scan.png" alt="">{{$t('index.QRA')}} <span class="isqr" @click="onWhats">{{$t('index.QRB')}}</span> {{$t('index.QRC')}}</div>
                <div class="qrcode_bom" @click="goInvite">{{$t('index.ALREADY')}}<img src="../../views/home/image/inviterg.png" alt=""></div>
            <div class="qrcodeclose" @mousedown="$emit('update:isqrcode', false)"><img src="../../views/home/image/inviteclose.png" alt=""></div>
        </div>       
    </div>
    
    <!-- <page-invite :isinvite.sync="isinvite"/> -->
  </div>
</template>

<script>
import { getQrcode } from "@/assets/api/index";
// import QrcodeVue from 'qrcode.vue'
export default {
    props:['isqrcode','isdetails'],
    data(){
        return{
           activeName:'first',
           isinvite:false,
           wxqrcode:'',
           whatqrcode:'',
           whatlink:''
        }
    },
    // components:{QrcodeVue},
    created(){
        
    },
    mounted(){
        this.getQrcode()
    },
    methods:{
        goInvite(){
            // this.isinvite = true
            // this.$emit('update:isqrcode', false)
            this.$emit('delete')
            
        },
        async getQrcode() {
            const res = await getQrcode({});
            if (res.code==200) {
                this.wxqrcode = res.data.sys_wx_qrcode
                this.whatqrcode = res.data.sys_whatsapp_qrcode
                this.whatlink = res.data.sys_whatsapp_link
            }
        },
        onWhats(){
            window.open(this.whatlink, "_blank");
        }

    }
}
</script>

<style lang='less' scoped>
.isqr{
    color: #2655F0;
    cursor: pointer;
}
.con{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9;
}

.con_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 500px;
    height: 620px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-sizing: border-box;
}
.qrcodeclose{
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    margin: 0 auto;
}
.qrcode_top{
    width: 100%;
    height: 200px;
    background: url('../../views/home/image/qrcodea.png') no-repeat 100%;
    margin-bottom: 30px;
    padding: 64px 48px;
    box-sizing: border-box;
}


.qrtit{
    font-size: 32px;
    font-family: alibaba-font;
    font-weight: 900;
    color: #FFFFFF;
}
.qrval{
    font-size: 26px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 8px;
    width: 310px;
}
.qrvalb{
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 8px;
    width: 275px;
}
.qrcode_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
}
.qrcodeimg{
    position: relative;
    width: 260px;
    height: 260px;
    background: url('../../views/home/image/qrshadow.png') no-repeat 100%;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
}
.qrcodeimg img{
    width: 152px;
    height: 152px;
}
::v-deep .el-tabs__nav-wrap::after{
    height: 0;
}
::v-deep .el-tabs__nav-scroll{
    padding-left: 30px;
}
::v-deep .el-tabs__item{
    font-size: 18px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #666666;
}
::v-deep .el-tabs__item:hover{
    color: #333333;
}
::v-deep .el-tabs__item.is-active{
    color: #333333;
}
::v-deep .el-tabs__active-bar{
    background-color: #2655F0;
}
.qrcode_scan{
    font-size: 16px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-top: -20px;
}
.qrcode_scan img{
  margin-right: 4px;
}
.qrcode_bom{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 66px;
    border-top: 1px dotted #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: alibaba-font;
    font-weight: 400;
    color: #2655F0;
}
.qrcode_bom img{
    cursor: pointer;
}

</style>